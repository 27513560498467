<template>
  <div class="app-main">
    <!-- 因为二级路由和三级路由都使用的此 template，所以需要在判断只显示对应级数的路由 -->
    <router-view v-slot="twoRoute">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="$store.getters.shopViewKeepAliveList">
          <component
            v-if="twoRoute.route.matched.length === 2"
            :is="twoRoute.Component"
            :key="twoRoute.route.path"
          />
        </keep-alive>
      </transition>

      <router-view v-slot="three">
        <transition name="fade-transform" mode="out-in">
          <keep-alive :include="$store.getters.shopViewKeepAliveList">
            <component
              v-if="three.route.matched.length === 3"
              :is="three.Component"
              :key="three.route.path"
            />
          </keep-alive>
        </transition>

        <router-view v-slot="four">
          <transition name="fade-transform" mode="out-in">
            <keep-alive :include="$store.getters.shopViewKeepAliveList">
              <component
                v-if="four.route.matched.length === 4"
                :is="four.Component"
                :key="four.route.path"
              />
            </keep-alive>
          </transition>
        </router-view>
      </router-view>
    </router-view>

    <!-- 小票机选择弹框 -->
    <TicketMachineSelect
      v-if="$store.getters.ticketMachineParams.flag"
      v-model="$store.getters.ticketMachineParams.flag"
      :source="$store.getters.ticketMachineParams.source"
      @print-receipt="$store.getters.ticketMachineParams.callback"
    />
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { isTags } from '@/utils/tagsView'
import { generateTitle, watchSwitchLang } from '@/utils/i18n'
import TicketMachineSelect from '@/layout/shop/components/cashier/TicketMachineSelect.vue'

/**
 * 生成 title
 */
const getTitle = (route) => {
  let title = ''
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = generateTitle(route.meta.title)
  }
  return title
}

const store = useStore()
const route = useRoute()
watch(
  route,
  (to, from) => {
    if (!isTags(route.path)) return
    // 路由变化时
    // 添加 tag
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to),
    })
  },
  {
    immediate: true,
  },
)

// 监听国际化变动，更改 tag 中的 title
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((tag, index) => {
    store.commit('app/changeTagsView', {
      index: index,
      tag: {
        ...tag,
        title: getTitle(tag),
      },
    })
  })
})
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px - 34px);
  width: 100%;
  min-width: 1605px;
  position: relative;
  overflow: hidden;
  padding: 120px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
