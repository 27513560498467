import store from '@/store'
import moment from 'moment-timezone'

/**
 * 根据时间戳转换成对应时区的 MM/DD/YYYY hh:mm A 格式
 *
 * 1703578332195 => 12/26/2023 04:12 PM
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzDatetime = (
  timestamp,
  timeZone = store.getters.timeZone,
) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const format = moment(timestamp).tz(timeZone).format('MM/DD/YYYY hh:mm A')
  return format
}

/**
 * 根据时间戳转换成对应时区的 MM/DD/YYYY 格式
 *
 * 1703578332195 => 12/26/2023
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzDate = (timestamp) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const timeZone = store.getters.timeZone
  const format = moment(timestamp).tz(timeZone).format('MM/DD/YYYY')
  return format
}

/**
 * 根据时间戳转换成对应时区的 MM/YYYY 格式
 *
 * 1703578332195 => 12/2023
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzMonth = (timestamp) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const timeZone = store.getters.timeZone
  const format = moment(timestamp).tz(timeZone).format('MM/YYYY')
  return format
}

/**
 * 根据时间戳转换成对应时区的 YYYY-MM-DD 格式
 *
 * 1703578332195 => 2023-12-26
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzDate2 = (timestamp) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const timeZone = store.getters.timeZone
  const format = moment(timestamp).tz(timeZone).format('YYYY-MM-DD')
  return format
}

/**
 * 根据时间戳转换成对应时区的 MMMM DD,YYYY 格式
 *
 * 1703578332195 => December 23, 2023
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzDate3 = (timestamp) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const timeZone = store.getters.timeZone
  const format = moment(timestamp).tz(timeZone).format('MMMM DD, YYYY')
  return format
}

/**
 * 根据时间戳转换成对应时区的 YYYY-MM-DD hh:mm:ss 格式
 *
 * 1703578332195 => 2023-12-25 16:00:00
 *
 * 主要应用于后台返回回显使用
 */
export const timestampFormatTzDatetime1 = (
  timestamp,
  timeZone = store.getters.timeZone,
) => {
  if (!timestamp || !timestamp.toString().match(/^\d{13}$/)) {
    return ''
  }
  const format = moment(timestamp).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')
  return format
}

/**
 * 把对应时区的 YYYY-MM-DD HH:mm:ss 格式转换成 UTC 时区的 YYYY-MM-DD HH:mm:ss 格式
 *
 * 2023-12-26 00:00:00 => 2023-12-25 16:00:00
 *
 * 主要应用于前端向后台传递日期时间格式
 */
export const dateStrFormatUtcDatetime = (dateStr) => {
  if (!dateStr || !dateStr.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return ''
  }
  const timeZone = store.getters.timeZone
  const tz = moment.tz(dateStr, 'YYYY-MM-DD HH:mm:ss', timeZone)
  const format = tz.utc().format('YYYY-MM-DD HH:mm:ss')
  return format
}

/**
 * 把指定 Date 转换成 YYYY-MM-DD HH:mm:ss 格式
 *
 * Date => 2023-12-26 00:00:00 格式
 */
export const formatDatetime = (date) => {
  if (!date) {
    return ''
  }
  const format = moment(date).format('YYYY-MM-DD HH:mm:ss')
  return format
}
/**
 * 把指定 Date 转换成 MM/DD/YYYY 格式
 *
 * Date => 12/26/2023 格式
 */
export const dateformatDateStr = (date) => {
  if (!date) {
    return ''
  }
  const format = moment(date).format('MM/DD/YYYY')
  return format
}

/**
 * 把YYYY-MM-DD HH:mm:ss 格式转换HH:mm A 格式
 *
 * 2023-05-13 18:00:00 => 06:00 PM
 *
 */
export const timeStrFormatTime = (dateStr) => {
  if (!dateStr || !dateStr.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return ''
  }
  const format = moment
    .tz(dateStr, 'YYYY-MM-DD HH:mm:ss', store.getters.timeZone)
    .format('hh:mm A')
  return format
}

// #region （废弃）打印机使用
export const getPrintingDateTime = () => {
  const date = new Date()

  // 创建一个DateTimeFormat对象，指定输出格式
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  // 使用formatter将date格式化为字符串
  return formatter.format(date)
}

export const convPrintingPaymentTime = (paymentTime) => {
  const date = new Date(paymentTime)

  // 创建一个DateTimeFormat对象，指定输出格式
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  // 使用formatter将date格式化为字符串
  return formatter.format(date)
}

export const convPrintingRefundTime = (refundExpiredTime) => {
  const date = new Date(refundExpiredTime)

  // 创建一个DateTimeFormat对象，指定输出格式
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })

  // 使用formatter将date格式化为字符串
  return formatter.format(date)
}
// #endregion
