export default {
  404: {
    uHOHYoureLost: '哦哟！你迷路了。',
    thePageYouAreLookingForDoesNotExist:
      '您正在寻找的页面不存在。您是如何到达这里的，仍然是个谜。但您可以点击下方按钮返回主页。',
    home: '主页',
  },
  toast: {
    switchLangSuccess: '切换语言成功',
    success: '成功',
    changeShopTip: '您已切换到其他店铺，需要刷新后才能继续操作。',
  },
  tagsView: {
    refresh: '刷新',
    closeOther: '关闭其他',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
  },
  navBar: {
    themeChange: '主题修改',
    headerSearch: '页面搜索',
    screenfull: '全屏替换',
    lang: '国际化',
    guide: '功能引导',
    home: '首页',
    organizationalSystem: '组织系统',
    course: '课程主页',
    logout: '退出登录',
    personalCenter: '个人中心',
  },
  guide: {
    close: '关闭',
    next: '下一个',
    prev: '上一个',
    guideTitle: '引导',
    guideDesc: '打开引导功能',
    hamburgerTitle: '汉堡按钮',
    hamburgerDesc: '打开和关闭左侧菜单',
    breadcrumbTitle: '面包屑',
    breadcrumbDesc: '指示当前页面位置',
    searchTitle: '搜索',
    searchDesc: '页面链接搜索',
    fullTitle: '全屏',
    fullDesc: '页面显示切换',
    themeTitle: '主题',
    themeDesc: '更换项目主题',
    langTitle: '国际化',
    langDesc: '语言切换',
    tagTitle: '标签',
    tagDesc: '已打开页面标签',
    sidebarTitle: '菜单',
    sidebarDesc: '项目功能菜单',
  },
  // 以下为正式业务使用
  login: {
    title: '用户登录',
    loginBtn: '登录',
    usernameRule: '邮箱格式不正确',
    passwordRule: '数字和字母构成，至少 6 位',
    resetPassword: '重置密码',
    email: '邮箱',
    verificationCode: '验证码',
    pleaseSetANewPassword: '请设置新密码',
    enterTheNewPasswordAgain: '再次输入新密码',
    selectTheOrganizationalSystemToLogIn: '选择要登录的组织系统',
    shopName: '店铺名称',
    joinTime: '加入时间',
    pleaseSelectAnOrganization: '请选择组织',
  },
  register: {
    signUp: '注册账号',
    storeCertification: '店铺认证',
    registerBtn: '下一步',
    usernameRule: '邮箱格式不正确',
    passwordRule: '数字和字母构成，至少 6 位',
    confirmPasswordRule: '密码不一致',
    passwordInconsistent: '密码不一致',
    verificationCodeEmailAddress: '验证码电子邮件地址',
    verificationCodeEmailPrefix: '为验证您的email，我们向',
    verificationCodeEmailSuffix: '发送了一个验证码',
    changEmail: '更改email',
    inputVerityCode: '输入验证码',
    createYourMasshoAccount: '创建您的Massho账户',
    resendCode: '重新发送验证码',
    verificationCodeMustBeFilled: '验证码不能为空',
    yourShopIs: '你的店铺是',
    aLoudStoreNameLetMorePeopleFind: '一个响亮的店铺名称让更多人发现您',
    pleaseEnterTheStoreName: '请输入店铺名称',
    yourWebAddressIs: '你的网址是',
    setUpACharacteristicUniqueWebsite: '设置一个有特点的网址，让客户更好找到您',
    pleaseEnterTheURLPrefix: '请输入网址前缀',
    theURLAlreadyExists: '该网址已存在，请重新输入',
    storeContactEmail: '店铺联系邮箱',
    aMailboxUsedToReceiveMailFromTheStore: '用于接收店铺邮件的邮箱',
    theEmailFormatIsIncorrect: '邮箱格式不正确',
    businessAddress: '营业地址',
    theExactLocationOfTheStore: '店铺所在的具体位置',
    pleaseEnterYourBusinessAddress: '请输入营业地址',
    businessAddressLength: '营业地址长度最多为100位',
    pleaseEnterUBINumber: '请输入UBI Number',
    pleaseProvideAValue: '请提供一个值',
    pleaseEnterTheEinForTheBore: '请输入Employer Identification Number',
    serviceIndustry: '服务行业',
    chooseAccurateIndustry: '选择精准行业，我们可以帮您更好的推广',
    selectYourBusinessType: '选择您的业务类型',
    industryCategory: '行业种类',
    chooseIndustryCategory: '选择精准种类，可以让更多人发现您',
    selectYourIndustryCategory: '选择您的行业种类',
    contactPhoneNumber: '联系手机号',
    masshoCustomerServiceTeamWillContactYouWithThisPhoneNumber:
      'Massho客服团队会使用该手机号与您联系',
    sendAShortMessageText: 'send messages',
    shopInformation: '店铺信息',
    storePicture: '店面图片',
    shopInfoDes:
      '图片上传参考示例（需上传带有店铺名称的完整外观图，没有店铺名称的可上传整体外观图）',
    frontDeskPicture: '前台图片',
    pictureUploadReferenceExample: '图片上传参考示例（需上传店铺前台完整图）',
    iHaveCarefullyRead: '我已认真阅读，理解并同意',
    massHOPrivacyStatement: '《MassHO隐私声明》',
    massHOUserAgreement: '《MassHO用户协议》',
    submit: '提交',
    privacyStatement: '隐私声明',
    userAgreement: '用户协议',
    back: '返回',
    existenceWithoutFillingItem: '存在未正确填写项',
    theServiceIndustryCannotBeEmpty: '服务行业不能为空',
    theIndustryCategoryCannotBeEmpty: '行业种类不能为空',
    isThereAGasStationOptionCannotBeEmpty: '是否有加油站选项不能为空',
    storePicturesAndFrontDeskPicturesCannotBeEmpty:
      '店面图片和前台图片不能为空',
    notAllThePicturesWereUploaded: '图片没有全部上传',
    pleaseReadTheAgreementCarefullyAndAgree: '请认真阅读协议并同意',
    verificationCode: '验证码',
    disposableVerificationCode: '一次性验证码',
    pleaseEnterTheVerificationCode: '请填写验证码',
    thePhoneNumberFormatIsIncorrect: '手机号格式不对',
    address: '地址',
    pleaseEnterAddress: '请输入地址',
    pleaseEnterApt: '请输入楼栋门牌号',
    aptLength: 'Apt长度最多为50位',
    city: '城市',
    cityLength: '城市最多为30位',
    pleaseEnterCity: '请输入城市',
    state: '州',
    pleaseEnterState: '请选择州',
    zipCode: '邮编',
    zipCodeLength: '邮编最多为5位',
    pleaseEnterZipCode: '请输入邮编',
    sellerInformation: '卖家信息',
    operatorInformation: '经营人信息',
    fillInTheInformationOfTheOperatorOfTheStoreThatNeedsToBeCertified:
      '填写需要认证店铺的经营人信息，经营人将拥有该店铺的所有权及法律责任。',
    firstName: '名',
    lengthShouldBe1To30: '长度为1至30位',
    lastName: '姓',
    enterYourFullNameAsItAppearsOnYourSocialSecurityCardOrDriverIsLicense:
      "Enter your full name as it appears on your Social Security card or driver's license",
    proofOfIdentity: '身份证明',
    socialSecurityCard: '社会安全卡',
    driverIsLicense: '驾照',
    issuingCountry: '签发国家/地区',
    unitedStates: '美国',
    sSNFormatIsIncorrect: 'SSN 格式不对，9 位数字',
    driverIsLicenseNumber: '驾照编号',
    lengthShouldBe1To15: '长度为1至15位',
    validityPeriod: '有效期',
    pleaseSelectTheValidityPeriodOfYourDriverIsLicense: '请选择驾照有效期',
    residenceAddress: '居住地址',
    residenceAddressCannotBeEmpty: '居住地址不能为空',
    nextStep: '下一步',
    signatureOfOperator: '经营人署名',
    clickToSign: '点击签名',
    pleaseSignInTheBlankAreaAndClickConfirmAfterSigning:
      '请在空白区域签名，签名完成后点击确认。',
    signatureCannotBeEmpty: '签名不能为空',
    reSignature: '重新签名',
    gasStationBusiness: '加油站业务',
    ifTheStoreHasRefuelingService: '店铺如果有加油业务，请选择勾选"是"',
    whetherThereIsAGasStationBusiness: '是否有加油站业务',
    brandLogo: '品牌LOGO',
    uploadYourBrandLogo: '上传您的品牌LOGO',
    shopPoster: '店铺海报',
    thePictureIsUsedToDisplayThePosterOfYourStoreOnTheUserSide:
      '图片用于在用户端展示您店铺的海报，上传带有与您店铺商品有关联的图片，好的店铺海报能促使用户下单',
  },
  settings: {
    operatorInformation: '经营者信息',
    accountSecurity: '账号安全',
    loginProtection: '登录保护',
    loginAccount: '登录账号',
    yourUniqueLoginInformation: '您的唯一登录信息',
    account: '账号',
    password: '密码',
    alreadySet: '已设置',
    changePassword: '密码修改',
    originalPassword: '原密码',
    newPassword: '新密码',
    confirmPasswordAgain: '再次确认密码',
    notTheSameAsTheNewPassword: '与新密码不相同',
    sessionTimeout: '会话超时',
    theTimeRangeIsFrom1To1440Minutes:
      '时间范围为1到1440分钟。如果在多个位置配置了会话超时，用户管理会话超时将具有最高优先级。如果在任何位置未配置会话超时，系统将默认为10分钟。如果超过配置的会话超时时间，将自动触发退出。',
    modifySessionTimeout: '修改会话超时',
    minutes: '分钟',
    sessionTimeoutCannotBeEmpty: '会话超时时间不能为空',
    notSet: '未设置',
  },
  route: {
    shopSelect: '店铺选择',
    account: '账号管理',
    roleManage: '组织角色管理',
    shopRoleManage: '店铺岗位管理',
    shopRole: '店铺角色',
    storePosition: '店铺岗位',
    // =============================
    shopIndex: '首页',
    analysisReport: '分析报告',
    // =============================
    onlineGoodsManage: '在线商品管理',
    pushGoods: '发布商品',
    pushGoods1: '编辑商品',
    pushGoods2: '查看商品',
    onlineProducts: '在线商品',
    releaseGoods: '商品录入',
    releaseGoods1: '编辑商品',
    releaseGoods2: '查看商品',
    platformClassify: '平台分类',
    platformClassifyAdd: '新增平台分类',
    platformClassifyDsc: '平台分类详情页',
    // =============================
    goodsManage: '商品管理',
    goodsManageGoodsListInfo: '商品信息',
    goodsClassify: '商品分类',
    goodsClassifyAdd: '新增商品分类',
    goodsClassifyAdd1: '编辑商品分类',
    goodsClassifyDsc: '商品分类详情',
    rawMaterial: '原材料管理',
    rawMaterialClassify: '原材料分类',
    rawMaterialClassifyAdd: '新增原材料分类',
    rawMaterialClassifyAdd1: '编辑原材料分类',
    rawMaterialClassifyDsc: '原材料分类详情',
    rawMeasureUnit: '计量单位',
    fuelCardInformation: '加油卡信息',
    rechargeCardService: '充值卡服务',
    shopManage: '店铺管理',
    shopSetting: '店铺设置',
    cashierManagement: '收银台管理',
    logisticsManagement: '物流管理',
    printersManagement: '打印机管理',
    afterSalesManage: '售后管理',
    afterSalesInfo: '售后列表',
    afterSalesDes: '售后详情',
    walletManage: '钱包管理',
    walletInfo: '钱包管理',
    creditLimitManagement: '额度管理',
    mortgageFunds: '抵押资金',
    OrderManage: '订单管理',
    SelfPickOrderInfo: '自提订单列表',
    selfPickOrderDes: '订单详情',
    DeliveryOrderInfo: '配送订单列表',
    deliveryOrderDes: '订单详情',
    OfflineOrderInfo: '线下订单',
    offlineOrderDes: '订单详情',
    offlineCheckout: '收银台',
    OnlineOrderInfo: '线上订单',
    onlineOrderDes: '订单详情',
    PickingTaskInfo: '拣货任务',
    StockManage: '库存管理',
    goodsStock: '商品库存',
    goodsStockInfo: '商品库存详情',
    rawmaterialStock: '原材料库存',
    rechargeCardStock: '充值卡库存',
    rawmaterialStockInfo: '原材料库存详情',
    shelfManage: '货架管理',
    guaranteeService: '保障服务',
    operGuaranteeTemplate: '新增保障服务',
    operGuaranteeTemplate1: '编辑保障服务',
    operGuaranteeTemplate2: '保障模板详情',
    guaranteeTemplate: '保障模板',
    guaranteeType: '保障类型',
    settings: '设置',
    notificationSettings: '通知设置',
    inventoryWarning: '库存预警',
    inventoryWarningSettings: '库存预警设置',
    purchaseManage: '采购管理',
    purchaseOrder: '采购订单',
    warehouseManage: '仓库管理',
    warehouse: '仓库',
    inbound: '入库',
    outbound: '出库',
    shelf: '货架',
    shelfWarning: '货架预警',
    shelfWarningSettings: '货架预警设置',
    shelfOutbound: '货架出库',
    outboundDetails: '出库详情',
    inboundDetails: '入库详情',
    putOnTheShelf: '上货架',
    operShelfGoods: '编辑商品',
    operShelfGoods1: '商品详情',
    purchaseOperDetails: '采购详情',
    purchaseOperDetails1: '复查采购订单',
    // =============================
    profile: '个人中心',
    user: '用户',
    excelImport: 'Excel导入',
    userManage: '用户管理',
    userInfo: '用户信息',
    roleList: '角色列表',
    permissionList: '权限列表',
    article: '文章',
    articleRanking: '文章排名',
    articleCreate: '创建文章',
    articleDetail: '文章详情',
    articleEditor: '文章编辑',
    shelfDesc: '货架管理详情',
    data: '数据',
    operatingStatement: '经营报表',
    summaryTable: '汇总表',
    orderDeductionDetails: '订单扣款明细',
    paymentDetails: '收款明细',
    tipDetails: '小费明细',
    taxesDetails: '税费明细',
    refundDetails: '退款明细',
    handover: '交班',
    handoverRecord: '交班记录',
    financial: '财务管理',
    cashPaymentReceipt: '现金缴款单',
    cashCollectionForm: '现金领取单',
    handoverDetails: '交班详情',
    cashPaymentReceiptDetails: '现金缴款单详情',
    cashCollectionFormDetails: '现金领取单详情',
    marketing: '营销管理',
    marketingActivities: '营销活动',
    sweepstakes: '抽奖活动',
    activityDetails: '详情',
    winningRecords: '中奖记录',
  },
  component: {
    searchUser: '搜索用户',
  },
  // 通用
  common: {
    other: '其他',
    modify: '修改',
    sorryInsufficientPermissions: '权限不足',
    sorryInsufficientPermissions1: '抱歉，权限不足',
    characterAtLeast: '字符至少: ',
    characterLengthLimit: '字符长度限制: ',
    uploadingInProgress: '正在上传中...',
    uploadImageErrorPleaseTryAgain: '上传图片出错，请重试',
    setting: '设置',
    exist: '有',
    none: '无',
    quantityMustBeGreaterThan0: '数量必须大于0',
    quantityMustBeGreaterThanOrEqualTo0: '数量必须大于等于0',
    submit: '提交',
    download: '下载',
    selectAll: '全选',
    pleaseSelect: '请选择',
    view: '查看',
    all: '全部',
    copySuccessful: '复制成功',
    warning: '警告',
    pleaseEnter: '请输入',
    length130Bits: '长度 1-30 位',
    search: '查询',
    reset: '重置',
    new: '新增',
    operation: '操作',
    edit: '编辑',
    details: '详情',
    delete: '删除',
    save: '保存',
    cancel: '取消',
    cannotBeEmpty: '不能为空',
    description: '描述',
    create: '创建',
    select: '选择',
    clear: '清除',
    add: '添加',
    more: '更多',
    basicInformation: '基本信息',
    verificationFailedThereAreUnfilledItems: '存在未正确填写项',
    status: '状态',
    pleaseSelectAStartTime: '开始时间',
    startTime: '开始时间',
    pleaseSelectAnEndTime: '结束时间',
    endTime: '结束时间',
    creationTime: '创建时间',
    to: '到',
    back: '返回',
    confirm: '确认',
    sendCode: '发送验证码',
    seconds: '秒',
    copy: '复制',
    copyUrl: '复制URL',
    change: '更改',
    verification: '验证',
    success: '成功',
    close: '关闭',
    yes: '是',
    no: '否',
    unknown: '未知',
    pleaseInputPassword: '请输入密码',
    lengthShouldBe6To20: '长度为6至20位',
    lengthShouldBe6To25: '长度为6至25位',
    theCurrentBrowserDoesNotSupportFullScreen: '当前浏览器不支持全屏',
    areYouSureToDeleteThis: '你确定要删除这个吗？',
    loginHasExpiredPleaseLogInAgain: '登录已失效，请重新登录',
    iKnow: '我知道了',
    emialError: '邮箱格式错误。 1-100 位，a-z，A-Z，0-9，.，_，-',
    emialPrefixError: '邮箱格式错误。 1-76 位，a-z，A-Z，0-9，.，_，-',
    warmTips: '温馨提示',
    incorrectFormatReference000: '参考: 0.00。格式不正确, 最长 8 位数',
    incorrectFormatReferenceThree: '参考: 0.00。格式不正确, 最长 3 位数',
    positiveInteger: '正整数',
    positiveIntegerGreaterThanZero: '> 0, 最长 8 位数',
    positiveIntegerGreaterThanZero1: '> 0, 最长 5 位数',
    positiveIntegerGreaterThanZero2: '>= 0, 最长 5 位数',
    mustBeGreaterThan0: '必须大于0',
    positiveIntegerGreaterThanOrEqualToZero: '>= 0, 最长 8 位数',
    resetLogin: '重新登录',
    resetLoginTips1: '会话超时，请重新登录',
    resetLoginTips2: '您需要前往',
    resetLoginTips3: '用户管理或组织角色管理',
    resetLoginTips4: '设置会话超时时间',
    minute: '分钟',
    noData: '暂无数据',
    zero: '0.00',
    tip: '提示',
    helpManual: '帮助手册',
    ok: 'OK',
    replace: '替换',
    imageSizePrompt: '请上传大小3M内的图片',
    uploadTip: '建议图片要求：比例为1:1，尺寸大小750px*750px以上，大小3M内',
    settingSuccess: '设置成功',
    clearSelected: '清空已选',
    viewSelected: '查看已选',
    viewDetails: '查看详情',
    combinationOfNumbersAndLowercaseLetters: '数字和小写字母的组合',
    type: '类型',
    result: '结果',
    operator: '操作人',
    nearly7Days: '近7天',
    nearly30Days: '近30天',
    otherTimes: '其他时间',
    storeStatusHasChanged: '店铺状态发生变更',
    cashierStatusChange: '当前小票机无法连接，请检查网络或设备状态',
    pleaseSelectTicketMachine: '请选择小票机',
    pleaseSelectAdministrator: '请选择管理员',
    administrators: '管理员',
    password: '密码',
    complete: '完成',
  },
  // 店铺选择
  storeSelect: {
    shopSelection: '店铺选择',
    pleaseChooseAShopToOpenYourBusiness: '请选择一个店铺开启您的生意',
    createANewStore: '创建新店铺',
    inBusiness: '营业中',
    resting: '休息中',
    underReview: '审核中',
    approved: '审核通过',
    auditRejected: '审核拒绝',
    atRest: '休息中',
    auditRejection: '审核拒绝',
    checkTheCause: '查看原因',
    rejectionReasons: '拒绝原因',
    cancel: '取消',
    toRevise: '去修改',
    ordersBy30Days: '30天订单数',
    totalOrderAmountInTheLast30Days: '30天订单总额',
    openTheShop: '正在打开店铺',
    canOnlyEnterTheStoreThatHasPassedTheReview: '只能进入审核通过的店铺',
  },
  accountManagement: {
    account: '账号',
    status: '状态',
    all: '全部',
    enable: '启用',
    disable: '禁用',
    addAccount: '新建账号',
    firstName: '名字',
    pleaseEnterFirstName: '请输入名字',
    lastName: '姓氏',
    pleaseEnterLastName: '请输入姓氏',
    pleaseEnterYourEmail: '请输入邮箱',
    organizationalRoles: '组织角色',
    pleaseSelectAnOrganizationRole: '请选择组织角色',
    afterThePersonIsSuccessfullyCreated:
      '创建人员成功后，登录初始密码：123456a',
    administratorName: '管理员名称',
    shopRole: '店铺角色',
    storePosition: '店铺岗位',
    editUser: '编辑账号',
    employee: '员工',
    operationGuide: '操作指南',
    byAddingEmployeesAndAssigningThemTheAppropriatePermissions:
      '通过添加员工，给他们分配相应的权限，他们将拥有访问您店铺的权限。',
    displayTheApprovedStoresAnd:
      '展示已经审核通过的店铺，给您的员工分配对应店铺岗位。',
    employeeName: '员工名称',
    shopName: '店铺名称',
    permissionAssignment: '权限分配',
    configurePermissions: '配置权限',
    rolesAlreadyAssigned: '已经分配的岗位',
    roleName: '岗位名称',
    newOrganizationRole: '新建组织角色',
    organizationRoleName: '组织角色名称',
    numberOfAssociatedAccounts: '关联账号数量',
    organizationPermissions: '组织权限',
    manageThePermissionsOfThisOrganization:
      '管理这个组织的权限，通过组织权限可以管理这个组织下多个店铺的权限。',
    selectAllPermissions: '选择所有权限',
    foldAll: '全部折叠',
    expandAll: '全部展开',
    editOrganizationRole: '编辑组织角色',
    viewOrganizationRole: '查看组织角色',
    organizationRoleDetails: '组织角色详情',
    newShopRole: '新增店铺岗位',
    shopRoleName: '店铺岗位名称',
    shopPermission: '店铺权限',
    permissionToManageThisStore:
      '管理这个店铺的权限，拥有此权限员工可在您店铺中查看的内容或执行的操作。',
    editShopRole: '编辑店铺岗位',
    viewShopRole: '查看店铺岗位',
    permissionToManageTheStore:
      '管理店铺的权限，员工可在您店铺中查看的内容或执行的操作。',
    numberOfRoles: '岗位数量',
    manageShopRoles: '管理店铺岗位',
    theShopDoesNotExist: '店铺不存在',
    loginAccount: '登录账号',
    yourUniqueLoginInformation: '您的唯一登录信息',
    accountEmail: '账户邮箱',
    fillInTheInformationOfTheOperatorOfTheStoreThatNeedsToBeCertified:
      '填写需要认证店铺的经营人信息，经营人将拥有该店铺的所有权及法律责任。',
    accordingToTheOperationSteps:
      '根据操作步骤来添加您的员工，并让他管理您的店铺。',
    addYourOrganizationRole: '添加您的组织角色',
    addYourStoreRole: '添加您的店铺岗位',
    thePermissionsForManagingThisStore:
      '管理这个店铺的权限，员工可在您店铺中查看的内容或执行的操作。',
    addYourEmployees: '添加您的员工',
    addYourEmployeesInUserManagementAndAssignThemCorrespondingPermissions:
      '在用户管理添加您的员工，给他们分配相应的权限，他们将拥有访问您店铺的权限。',
    pleaseSelectPermissions: '请选择权限',
    thePasswordResetFor: '账号',
    wasSuccessful: '重置密码成功。',
    initialLoginPassword123456a: '登录初始密码：123456a',
    sessionTimeout: '会话超时',
    sessionTimeoutTips:
      '时间范围为1到1440分钟。如果在多个位置配置了会话超时，用户管理会话超时将具有最高优先级。如果在任何位置未配置会话超时，系统将默认为10分钟。如果超过配置的会话超时时间，将自动触发退出。',
  },
  indexManage: {
    totalOrdersToday: '今日订单总数',
    totalSalesToday: '今日销售总额',
    todaysReturnOrder: '今日退货单',
    refundAmountToday: '今日退款金额',
    productOverview: '商品概览',
    analysisReport: '分析报告',
    allProducts: '全部商品',
    onSale: '已上架(在线商店)',
    offSale: '已下架(在线商店)',
    inventoryWarning: '库存预警',
    inventoryWarningOnlineStore: '库存预警(在线商店)',
    shelfWarning: '货架预警',
    countTheQuantityOfYourInsufficientGoodsOrRawMaterialInventory:
      '统计您商品数或原材料库存不足的数量',
    productInventory: '商品库存',
    rawMaterialInventory: '原材料库存',
    goToHandle: '去处理',
    reportMsg:
      '1. 销售额=商品单价*个数（去除已经退款的)。<br />2. 毛利润=销售额-商品成本价。<br />3. 退货商品比例=退货商品个数/已售商品个数。<br />4. 订单数=如果一个订单有多个一样的SKU，记为1个订单数。',
    theAnalysisReportDataStatisticsDoNotIncludeTheCurrentDay:
      '分析报告数据统计不包含当日',
    productName: '商品名称',
    time: '时间',
    days: '天',
    theQuantityOfYourProductIsLessThan10: '您的产品数量低于10个无法分析',
    productNameSpecification: '商品名称/规格',
    salesRevenue: '销售额',
    grossProfit: '毛利润',
    returnProductRatio: '退货商品比例',
    numberOfOrders: '订单数',
    pendingTransactions: '待处理事务',
    abnormalOrder: '异常订单',
    pickingTask: '拣货任务',
    picture: '图片',
  },
  goodsManage: {
    duplicateSpecificationNameExists: '存在重复的规格名',
    duplicateSpecificationValuesExist: '存在重复的规格值',
    noRawMaterialsAvailable: '暂无原材料',
    goAdd: '去添加',
    productName: '商品名称',
    productClassification: '商品分类',
    pleaseEnter: '请输入',
    search: '查询',
    reset: '重置',
    new: '新增',
    productID: '商品ID',
    productPictures: '商品图片',
    productNameAndSpecification: '商品名称/规格',
    barCode: '条码',
    creationTime: '创建时间',
    operation: '操作',
    edit: '编辑',
    details: '详情',
    delete: '删除',
    basicInformation: '基本信息',
    classifyTip: '如果商品分类没有符合要求的，请在商品分类添加后再选择',
    unit: '单位',
    taxRate: '税率',
    taxTip: '可对指定的商品自定义收取税费',
    thisIsComposedOfRawMaterials: '此商品原材料构成',
    productSpecificationsAndInventory: '商品规格',
    specificationName: '规格名',
    specificationValue: '规格值',
    pleaseSelect: '请选择',
    addSpecification: '添加规格',
    sellingPrice: '售价',
    originalPrice: '原价',
    costPrice: '成本价',
    productParticipatingInPromotion: '商品参与活动',
    usageRatioOfMacou: 'macou 使用比例',
    macouTip:
      '用户购买此商品享受最大抵扣比例/最大返币比例，红瓶抵扣和蓝瓶返利只能2选一。',
    deduction: '抵扣',
    deductionOrRebate: '抵扣/返币',
    rebate: '返利',
    rebate1: '返币',
    save: '保存',
    cancel: '取消',
    cannotBeEmpty: '不能为空',
    gtZero: '大于或等于0的整数/小数',
    canOnlyHaveUpTo8Digits: '最多只能有8位',
    geOnlyZero: '只能为大于或等于0的整数',
    gtOnlyZero: '只能为大于0的整数',
    verificationFailedThereAreUnfilledItems: '存在未正确填写项',
    description: '描述',
    correlationQuantity: '关联数量',
    des: '描述',
    pleaseEnterACategoryName: '请输入分类名称',
    classificationDescription: '分类描述',
    pleaseEnterTheCategoryDescription: '请输入分类描述',
    create: '创建',
    lengthShouldBeBetween2And10: '长度2到10之间',
    lengthShouldBeBetween2And20: '长度2到20之间',
    thisCategoryIsNotRelated: '该商品分类暂无关联商品',
    relatedGoods: '关联商品',
    nameOfRawMaterial: '原材料名称',
    classificationOfRawMaterials: '原材料分类',
    rawMaterialID: '原材料ID',
    picture: '图片',
    numberOfAssociatedProducts: '关联商品数量',
    addRawMaterialInformation: '新增原材料信息',
    editRawMaterialInformation: '编辑原材料信息',
    rawMaterialInformationDetails: '原材料信息详情',
    classificationOfRawMaterialsTip:
      '如果原材料分类没有符合要求的，请在原材料分类添加后再选择。',
    unitTip: '原材料请选择最小单位，方便管理库存',
    rawMaterialPicture: '原材料图片',
    rawMaterialSpecification: '原材料规格',
    inventoryQuantity: '库存数量',
    theInventoryQuantityWillBe: '该库存数量将会同步库存管理【原材料库存】',
    categoryNames: '分类名称',
    relatedRawMaterials: '关联原材料',
    thisClassificationHasNo: '该分类暂无关联原材料',
    nameOfUnit: '单位名称',
    unitType: '单位类型',
    status: '状态',
    unitSource: '单位来源',
    enable: '启用',
    disable: '禁用',
    newUnit: '新增单位',
    compositionOfRawMaterialsForThisProduct: '此商品原材料构成',
    compositionOfCommodityRawMaterials: '商品原材料构成',
    copyRawMaterials: '复制原材料',
    rawMaterials: '原材料',
    quantityOfRawMaterials: '原材料数量',
    clickToSelectRawMaterials: '点击选择原材料',
    selectionOfRawMaterials: '原材料选择',
    shelfLocation1: '货架位置',
    pleaseFillInTheFormBeforeCopying: '请填写完整之后再复制',
    setRedTips:
      '设置红瓶抵扣/返币，代表用户购买此商品享受最大抵扣比例，如果对应的该比例用户使用现金支付，由商家返还相应的macou，比如（一件商品100美元，设置50%抵扣），如果用户使用100美元购买此商品，将会得到50macou.',
    setBlueTips:
      '设置蓝瓶返币，代表用户购买此商品只能返币，比如（一件商品100美元，设置100%返币），用户购买此商品将会得到100macou.',
    goodNoTips: '商品号：商家可以设置一个不重复的商品号，方便记忆。',
    shelfLocation: '货架位置：商品需要上架至对应货架位置。',
    scaleCantNotEmpty: '请选择Macou使用比例',
    shelfNullTips1: '如果未找到匹配的货架位置，请在货架管理中进行添加，',
    shelfNullTips2: '去添加>',
    thisProductHasBeenPutOnTheOnlineStoreAndCannotBeChanged:
      '该商品已上架线上店铺，不允许更改。',
    thisProductHasBeenAddedToTheOnlineStoreAndCannotBeDeleted:
      '该商品已上架线上店铺，不允许删除。',
    fuelCardName: '加油卡名称',
    fuelCardPicture: '加油卡图片',
    parValue: '面值',
    addedFuelCard: '新增加油卡信息',
    parValueOfFuelCard: '加油卡面值',
    cashMacouReturnCoin: '现金 Macou 返币',
    thePaymentMethodIsDifferent: '支付方式不同，Macou返币不一致',
    macouReturnCurrencyValue: 'Macou 返币数值，请选择对应的加油卡面值',
    cardPayMacouReturnCoin: '卡支付 Macou 返币',
    lengthShouldBe1To75: '长度应为 1 到 75',
    macouReturnCoin: 'Macou 返币',
    fuelCardInformation: '加油卡信息',
    addFuelCard: '新增加油卡',
    editFuelCard: '编辑加油卡',
    viewFuelCard: '查看加油卡',
    addRechargeCard: '新增充值卡',
    editRechargeCard: '编辑充值卡',
    viewRechargeCard: '查看充值卡',
    enablePackagingUnits: '启用包装单位',
    theBasicUnitIsTheSmallestSellingUnitOfACommodity:
      '基本单位为商品的最小售卖单位，如果商品还有其他单位，可以启用包装单位。',
    packagingUnit: '包装单位',
    unitConversion: '单位换算',
    pleaseFillInTheUnitConversionCarefully:
      '单位换算请谨慎填写，一经启用，则不允许取消或修改。',
    packagingBarcode: '包装条码',
    selectExistingImage: '选择已有图片',
    functionSettings: '功能设置',
    guaranteeService: '保障服务',
    ifYourProductSupportsGuaranteeServices:
      '如果您的商品支持保障服务，则填写保障服务的相关信息。保障服务也有享受Macou抵扣或返利。',
    selectGuarantee: '选择保障',
    selectType: '选择类型',
    guaranteeType: '保障类型',
    guaranteeName: '保障名称',
    ifNoMatchingCoverageNameIsFound:
      '如果未找到匹配的保障名称，请在保障模板中添加。',
    pleaseSelectTheTypeOfGuaranteeFirst: '请先选择保障类型',
    PleaseSelectTheGuaranteeServiceFirst: '请先选择保障服务',
    serviceSupportsMacouDeductionOrRebate: '服务支持Macou抵扣或返利',
    checkTheOptionToSupportMacouRebates: '勾选支持Macou返利，比例将和商品一致',
    price: '价格',
    validityPeriod: '有效期(天)',
    type: '类型',
    commodity: '商品',
    rawMaterialCommodities: '原材料商品',
    batchSet: '批量设置',
    cannotBeCanceledOrModifiedAfterSaving: '保存后不能取消或修改',
    unitKind: '计量种类',
    basicUnit: '基本单位',
    cannotBeCanceledAfterSaving: '保存后不能取消',
    purchasingCycle: '采购周期',
    purchasingCycleTips:
      '建议填写最长的采购周期，即发起采购到入库的最长耗时，以免发生断货。',
    stockingTime: '备货时间',
    stockingTimeTips:
      '对于特殊商品，您可以设定了一个独立的备货时间，这表示需要额外的时间来准备这个商品。',
    singleDaySalesLimit: '单日售卖上限',
    singleDaySalesLimitTips:
      '商品每日售卖有上限，包括线上和线下订单。一旦单日销售量达到上限，商品状态将显示为"无货"。这个限制每天在00:00 AM进行更新。如果未设置单日售卖上限，系统默认为无穷大。',
    day: '天',
    example: '示例',
    productPurchaseLimit: '商品限购数量',
    productPurchaseLimitTips:
      '总限购数量=基础限购数量+邀请新用户限购数量。如果不填写代表商品不限制购买数量。',
    basicPurchaseLimit: '基础限购数量',
    inviteExtraQuantity: '邀请一个新用户额外获得限购',
    productNumber: '商品号',
    productType: '商品类型',
    specifications: '规格',
    purchaseTime: '进货时间',
    itIsRecommendedToFillInTheLongestProcurementTime:
      '建议填写最长的进货时间，即发起采购到入库的最长耗时，以免发生断货。',
    relatedProducts: '关联商品',
    numberOfUses: '使用数量',
    refuelingCard: '加油充值卡',
    universalRechargeCard: '通用充值卡',
    name: '名称',
    rechargeCardType: '充值卡类型',
    updateGoodsTips:
      '商品已发布至线上店铺，以下信息如有修改，将会同步更新到线上店铺:',
    updateCardTips:
      '充值卡已发布至线上店铺，以下信息如有修改，将会同步更新到线上店铺:',
  },
  onlineGoodsManage: {
    productName: '商品名称',
    onlineProducts: '在线商品',
    manageTheProductsOfYourOnlineStore:
      '管理您线上店铺的商品，用户将会通过APP浏览您的店铺及购买商品。',
    upload: '上传',
    all: '全部',
    onSale: '已上架',
    onSale2: '上架',
    offSale: '未上架',
    offSale2: '下架',
    soldOut: '已售罄',
    productImage: '商品图片',
    inventory: '库存',
    availableInventory: '可售库存',
    availableInventoryTheSumOfTheMaximumSellableQuantitiesForEachSKUOfAProduct:
      '可售库存=一个商品各自SKU最大可售卖数量之和。<br />·如果商品SKU部分没有库存，展示部分缺货。<br />·如果商品全部SKU都没有库存，展示缺货。',
    partiallyOutOfStock: '部分缺货',
    outOfStock: '缺货',
    replenish: '补货',
    batchOperation: '批量操作',
    publish: '发布',
    publishGoods: '发布商品',
    nextStep: '下一步',
    oneClickPublishing:
      '一键发布：可以批量上传，适用于不修改商品任何信息(如：商品售价、售卖上限等操作)，选择完毕后直接发布。',
    editAndPublish: '编辑发布：指定商品进行编辑上架，一次只能上架一件商品。',
    publishingMethod: '发布方式',
    oneClickPublish: '一键发布',
    editPublish: '编辑发布',
    productID: '商品 ID',
    productCategories: '商品分类',
    deliveryMethod: '配送方式',
    selfPickUp: '自提',
    delivery: '配送',
    toManage: '去管理',
    selectTheTypeOfGoodsToSupportDistribution:
      '选择商品支持配送的类型，支持自提、配送多选。',
    makeSureToRelease: '确定将 ',
    items: ' 件商品发布',
    afterThePublicationIsSuccessful:
      '发布成功后，将在在线商品列表展示，您还需要手动上架商品。',
    releaseRechargeCard1: ' 件充值卡发布',
    afterThePublicationRechargeCardIsSuccessful:
      '发布成功后，将在在线商品列表展示，您还需要手动上架充值卡。',
    basicInformation: '基本信息',
    taxRate: '税率',
    productSpecificationsAndInventory: '商品规格与库存',
    setSalesLimit: '设置售卖上限',
    ifYouWantToSellPartOfTheNumberOfGoods:
      '如果想部分售卖商品数量，请勾选设置售卖上限，如未勾选，商品售卖数量 = 总库存数量',
    productNumber: '商品号',
    productNumberMerchantsCanSetAUniqueProductNumberForEasyMemorization:
      '商品号：商家可以设置一个不重复的商品号，方便记忆。',
    picture: '图片',
    barcode: '条码',
    shelfLocation: '货架位置',
    shelfLocationTheGoodsNeedToBePlacedOnTheCorrespondingShelfLocation:
      '货架位置：商品需要上架至对应货架位置。',
    salesLimit: '售卖上限',
    totalInventory: '总库存数',
    statusSettings: '状态设置',
    additionalDeliveryFee: '附加配送费',
    extraAdditionalDeliveryFeesCanBeSetForTheCurrentProduct:
      '可对当前商品设置额外附加配送费。',
    productDetails: '商品详情',
    productDescription: '商品描述',
    brieflyDescribeTheInformationOfTheProductToLetMorePeopleUnderstandTheProductInformation:
      '简单的描述下商品的信息，让更多人了解商品信息。',
    descriptionOfProductInformation:
      '描述商品的信息。可以让用户快速的了解商品的作用。',
    confirmSettingThisProductAs: '确定将此商品设置为',
    theListedProductsWillBeDisplayedInTheStore:
      '已上架的商品将在店铺展示，你的客户可以浏览及购买',
    theGoodsThatHaveBeenRemovedFromTheShelvesWillBeHiddenInTheStoreAndCustomersWillNotBeAbleToViewThem:
      '已下架的商品将在店铺隐藏，客户无法查看',
    afterSuccessfulDeletion: '删除成功后，商品列表将不再展示此商品',
    pleaseSelectTheItemFirst: '请先选择商品',
    replenishment: '补货',
    guideToPublishingProducts: '发布商品指南',
    uploadYourProductsToTheOnlineStoreForSaleAccordingToTheOperationSteps:
      '根据操作步骤把您商品上传至线上店铺售卖',
    addYourProduct: '添加您的商品',
    addYourProductInTheProductInformation: '在商品信息添加您的商品。',
    addYourLogisticsInformation: '添加您的物流信息',
    createLogisticsInformationThatMatchesYourOnlineStore:
      '创建符合你线上店铺的物流信息。',
    publishProducts: '发布商品',
    onlineProductRelease:
      '在线商品发布您的商品，用户将会通过APP发现您的店铺及浏览商品。',
    successfullyPublished: '成功发布',
    successfullyOnSale: '成功上架',
    successfullyRemoval: '成功下架',
    itemsWith: '件商品，有',
    itemFailedToBePublished: '件商品发布失败。',
    itemFailedToBeOnSale: '件商品上架失败。',
    itemFailedToBeRemoval: '件商品下架失败。',
    successfulPublication: '发布成功',
    successfulPublicationPartialFailure: '发布成功，部分失败',
    failedToPublish: '发布失败',
    chooseAtLeastOneDeliveryMethod: '配送方式至少选一个',
    saleOfLimitedMerchandise: '售卖上限商品',
    productType: '商品类型',
    outOfStockStatus: '缺货状态',
    supplementInventoryForInventoryManagement: '去库存管理补充库存',
    putTheGoodsOnTheShelves: '将商品上架货架',
    putTheGoodsOnTheShelves1: '将商品上架至货架。',
    product: '商品',
    rawMaterialsProduct: '原材料商品',
    salesMethod: '销售方式',
    regularProduct: '普通商品',
    preSaleProduct: '预售商品',
    rechargeCard: '充值卡',
    productNameAndSpecification: '商品名称/规格',
    storeInventoryTotal: '商店可用总库存',
    availableActualInventoryMinusOtherOccupiedInventory:
      '可用=实际库存减去其他占用的库存，即可随时发货的库存。',
    unit: '单位',
    thisUnitDisplaysTheBaseUnit: '此单位展示的是基本单位',
    salesMethodIsARequiredField: '销售方式为必选项',
    publishResults: '发布结果',
    reasonForFailure: '失败原因',
    shelfProducts: '上架商品',
    shelfCard: '上架充值卡',
    releaseRechargeCard: '发布充值卡',
    oneClickPublishingItCanBeUploadedInBatches:
      '一键发布：可以批量上传，适用于不修改充值卡任何信息(如：面值、参与活动等操作)，选择完毕后直接发布。',
    editAndPublishSpecifyTopUpCardsForEditingAndPublishing:
      '编辑发布：指定充值卡进行编辑发布，一次只能发布一件充值卡。',
    name: '名称',
    refuelingCard: '加油充值卡',
    universalRechargeCard: '通用充值卡',
    type: '类型',
    parValue: '面值',
    returnCoins: '返币',
    theReturnCoinsCannotBeEmptyAtLeast0: '返币不能为空，至少为 0',
    pleaseUploadTheImage: '请上传图片',
    productImageCannotBeEmpty: '商品图片不能为空',
    basicUnit: '基本单位',
    enablePackagingUnits: '启用包装单位',
    packagingUnit: '包装单位',
    unitConversion: '单位换算',
    thisIsComposedOfRawMaterials: '此商品原材料构成',
    purchaseTime: '进货时间',
    stockingTime: '备货时间',
    singleDaySalesLimit: '单日售卖上限',
    batchSet: '批量设置',
    preSaleItemsPreSaleItemsReferToProductsThatAreNotInStockInTheStoreAndCanOnlyBePurchasedOnline:
      '预售商品：预售商品是指店铺没有现货的商品，只能通过线上购买。',
    specifications: '规格',
    shelfLocal: '货架位置',
    shelfLocationTheProductNeedsToBePutOnTheShelfToTheCorrespondingShelfPosition:
      '货架位置：商品需要上架至对应货架位置。',
    limitedPurchaseQuantityOfGoods: '商品限购数量',
    totalPurchaseLimitQuantity:
      '总限购数量=基础限购数量+邀请新用户限购数量。如果不填写代表商品不限制购买数量。',
    uploadImage: '上传图片',
    thePictureOfSpecification: '规格 [',
    isInsufficientPleaseUploadItFirst: '] 的图片不足，请先上传。',
    editRechargeCard: '编辑充值卡',
    rechargeCardDetails: '充值卡详情',
    logisticsInformationNotConfigured: '物流信息未配置',
    youNeedToGoToThe: '您需要前往',
    logisticsManagement: '物流管理',
    settingsToSetUpRelevantInformation:
      '设置相关信息，设置完成后，您即可顺利发布商品。',
    unlimited: '无限',
    theTotalAvailableInventoryOfTheProductIsInsufficient:
      '商品可用总库存不足，请尽快采购。',
    oneClickRelease:
      '一键发布：可以批量上传，适用于不修改商品任何信息(如：商品售价、参与活动等操作)，选择完毕后直接发布。',
    editAndPublish1: '编辑发布：指定商品进行编辑发布，一次只能发布一件商品。',
    productSpecifications: '商品规格',
    settings: '设置',
    offSaleTime: '下架时间',
    scheduledDelisting: '定时下架',
    scheduledDelistingTip:
      '当商品达到定时下架时间，系统自动将商品变为下架，用户预定该商品最大时间不能超过定时下架时间。',
    pleaseChooseOffSaleTime: '请选择下架时间',
    offSaleTimeLessThanNow: '下架时间小于当前时间',
    onSaleTip: '该商品已从货架上移除。请先将其添加回货架，然后再继续操作。',
    onSaleResult: '上架结果',
    onSaleResultTip: '成功上架X件商品,有Y件商品上架失败。',
    removalResult: '下架结果',
  },
  shopManage: {
    storeConfiguration: '店铺配置',
    shopInformation: '店铺信息',
    addBusinessTime: '添加营业时段',
    businessHours: '营业时间',
    businessDate: '营业日期',
    businessTime: '营业时段',
    pleaseSelectAStartTime: '开始时间',
    pleaseSelectAnEndTime: '结束时间',
    restDay: '当天休息',
    allDayRest: '全天休息',
    selectTime: '选择时间',
    sunday: '周日',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    orderRefundPeriod: '订单退款期限',
    taxRate: '税率',
    accountManagement: '账户管理',
    accountStatus: '账户状态',
    linked: '已绑定',
    unlinked: '未绑定',
    linkNow: '去绑定',
    bindingInProgress: '正在绑定',
    enterTheVerificationPhoneNumber: '输入通过短信向您手机号（+1 ',
    viaSMS: '）发送的验证码',
    newCashier: '新增收银台',
    cashierName: '收银台名称',
    bindingTime: '绑定时间',
    notBound: '未绑定',
    bound: '已绑定',
    bindPOSMachine: '绑定Reader',
    unbind: '解绑',
    pOSDeviceCode: 'Reader 设备编码',
    pleaseEnterTheDeviceCode:
      '请将“Reader 设备编码”输入到您的 Reader 机中，绑定成功后刷新页面即可',
    ifYouWantToUseTheShopFunctionNormally: '如果想正常使用店铺功能，必须绑定',
    verificationFailedBusinessDate: '请完善营业时间',
    pleaseSaveBusinessHour: '请保存营业时间',
    businessDateTips1: '营业日期如果未勾选，',
    businessDateTips2: '则代表对应日期休息',
    preOrderSettings: '预订单设置',
    restTimeSupportsReservation: '休息时间支持预定',
    restTimeDoesNotSupportReservation: '休息时间不支持预定',
    merchantsReceiveBookingDates: '商家接收预定日期',
    reUpload: '重新上传',
    uploadYourBrandLogo: '上传您的品牌 LOGO',
    thePictureIsUsedToDisplayThePosterOfYourStoreOnTheUserSide:
      '图片用于在用户端展示您店铺的海报，上传带有与您店铺商品有关联的图片，好的店铺海报能促使用户下单',
    selfPickUp: '自提',
    delivery: '配送',
    selfPickupSettings: '自提设置',
    stockPreparationTime: '备货准备时间',
    hour: '小时',
    minute: '分钟',
    thePreparationTimeForStockingAndTheTimeForPrintingReceiptsForSelfPickupOrdersAreRelated:
      '备货准备时间和自提订单出小票时间相关，比如：备货准备时间设置为30分钟，用户选择自提时间为：10:00AM，出小票时间为：9:30AM。',
    cannotBeGreaterThan60: '不能大于 60',
    serviceArea: '服务区域',
    setTheServiceRangeForYourStore:
      '给您的店铺设置服务范围，通过邮编来划分您的营业范围（例如选择20500，则在APP端只有"20500"的范围内的用户才能浏览到您的店铺）。',
    addPostalCode: '添加邮编',
    pleaseClick: '请点击',
    state: '州',
    city: '城市',
    deliverySettings: '配送设置',
    setDifferentServiceAreaDeliveryDatesForYourStore:
      '给您的店铺设置不同服务区域配送日期，您也点击"每日配送"选择每天都配送。',
    deliveryDate: '配送日期',
    deliveryArea: '配送区域',
    selectPostalCode: '选择邮编',
    postalCode: '邮编',
    deliverySettingsHaveBeenReset: '下方配送设置已重置，请重新配置',
    dailyDelivery: '每日配送',
    allServiceAreasAreDeliveredDaily:
      '所有服务区域每天配送，如果您需要给不同服务区域设置不同配送时间，请关闭右上角每日配送按钮。',
    billingMethod: '计费方式',
    usedToCalculateTheDeliveryCostOfGoods: '用于计算商品的配送费用。',
    freightPrice: '运费价格',
    pleaseEnterTheShippingFeePrice: '请输入运费价格',
    ifTheTotalPriceOfTheOrderIsNotWithinTheRange:
      '如果订单总价不在区间范围内，则使用运费价格计费。',
    addConditions: '添加条件',
    deleteConditions: '删除条件',
    byOrderTotalPrice: '按订单总价',
    reminderTheIntervalFollowsThe:
      '提醒：区间遵循"左闭右开"规则，例：0-50＄，代表：0 ≤ X ＜ 50＄',
    exampleForATotalProductPriceOf:
      '例：按商品总价0-50＄，运费价格25＄，代表：订单商品总价在0-50＄，所需运费价格25＄',
    interval: '区间',
    endMustBeGreaterThanStart: '结尾必须要大于开始',
    chooseAtLeastOneDay: '至少选择一天',
    newPrinter: '新增小票机',
    printerName: '小票机名称',
    offline: '离线',
    online: '在线',
    printTest: '打印测试',
    lengthShouldBe1To20: '长度应为1到20',
    bindingCashier: '绑定收银台',
    setAsMainTicketMachine: '设置为主小票机',
    afterSuccessfulSetup:
      '设置成功后，该小票机将自动打印配送、自提的订单及线下收银出小票功能，未勾选可以正常用于 线下收银出小票。',
    editPrinter: '编辑小票机',
    printerDetails: '小票机详情',
    requestSuccessfulPleaseObserveThePrinter: '请求成功，请观察小票机',
    orderPreparationTime: '订单备货时间',
    upTo2ModificationsPerDay: '每日最多修改2次',
    setTime: '设置时间',
    fillingInstructions: '填写须知',
    setYourOrderPreparationTimeAccordingToTheActualOperationOfYourStorePleaseFillItInAccurately:
      '1. 根据您店铺的实际经营情况设置您的订单备货准备时间，请准确填写。',
    fillingInAReasonableStockingPreparationTimeCanBringABetterExperienceToUsers:
      '2. 填写合理的备货准备时间可以给用户带来更好的体验，若您填写的时间过长或过短，影响用户的取货时间，从而影响您的店铺信誉。',
    ifYouModifyTheLeadTimeForStockingPreparation:
      '3. 若您修改了备货准备时间，只会在新订单生效，历史订单不会受到影响。',
    youCanDivideYourDeliveryServiceAreaBySettingThePostalCode:
      '您可以通过设置邮编来划分您的配送服务范围，使得只有特定范围内的用户能够浏览到您的店铺。用户选择“到店”时则不受服务区域限制。',
    orderDeliveryCutOffTimeForReceivingOrders: '订单配送截止接单时间',
    orderDeliveryCutOffTimeForReceivingOrdersXHoursBeforeTheEndOfTheBusinessDay:
      '订单配送截止接单时间：距离当天营业时间X小时，假设填写的是2小时，当天营业时间8:00 AM-6:00PM，则距离当天营业结束之前2小时内，用户在APP就不能预定当日的配送订单。如果不填写则代表接单截止时间=当天营业结束时间。',
    beforeTheEndOfBusinessThatDay: '当天营业结束前',
    hours: '小时',
    setMainPrinter: '设置主打印机',
    afterTheSuccessfulSetup:
      '设置成功后，该小票机将打印拣货单，线上小票，同时也具备线下收银出小票功能。',
    selectAtLeastOneTicketMachine: '至少选中一个小票机',
    tip: '小费',
    userIfHaveCheckTip: '用户支持自定义小费',
    orderStockingTimeTips: '订单备货时间不能为零，请设置有效的时间',
    cashPaymentRewards: '现金支付奖励',
    actualAmountPaid: '实付金额',
    cashPaymentRewardsTips:
      '会员使用现金支付时，将会额外获得一笔 Macou 奖励。奖励金额 = 订单实付金额 * X%',
  },
  orderManage: {
    offlineOrder: '线下订单',
    onlineOrder: '线上订单',
    pickingTask: '拣货任务',
    orderTime: '下单时间',
    to: '到',
    orderNumber: '订单编号',
    offlineCollection: '线下收款',
    productName: '商品名称',
    quantity: '数量',
    qty: '数量',
    amountReceived: '收款金额',
    amountReceived1: '实收金额',
    methodOfPayment: '支付方式',
    payType: '支付方式',
    basicProductInformation: '商品基本信息',
    productID: '商品ID',
    productPictures: '商品图片',
    sKUInformation: 'SKU信息',
    unitPrice: '单价',
    totalPrice: '总价',
    orderInformation: '订单信息',
    taxAndFees: '税费',
    taxAndFees1: '税费',
    confirmClearingThePurchaseList: '确定清除购买列表？',
    amountDue: '应付金额',
    subtotal: '小计',
    macouDeduction: 'macou 抵扣',
    macouUse: 'Macou 抵扣',
    macouRebate: 'Macou 返利',
    customerType: '客户类型',
    actualPaymentAmount: '实付金额',
    total: '总计',
    afterSalesInformation: '售后信息',
    afterSalesID: '售后ID',
    hasTheProductBeenReturnedToTheWarehouse: '商品是否退回仓库',
    buyerAccount: '买家账户',
    applicationAmount: '申请金额',
    refundAmount: '退款金额',
    cashier: '收银台',
    individualCustomer: '散客',
    member: '会员',
    selectCustomerType: '请选择客户类型',
    mobilePhoneNumber: '手机号',
    product: '商品',
    receivable: '应收款',
    paymentMethod: '收款方式',
    cash: '现金',
    cardPayment: '卡支付',
    onlinePayment: '线上支付',
    paymentReceived: '已收款',
    initiatePayment: '发起收款',
    specifiedPrice: '指定价格',
    unit: '单位',
    amount: '金额',
    remaining: '剩余',
    classification: '分类',
    atLeastOneExists: '至少存在一个',
    collectingPayment: '正在收款',
    pleaseSelectCashier: '请选择收银台',
    pleaseSelectTheCorrespondingCashier: '请选择对应的收银台',
    pleaseSelectPaymentChannel: '请选择支付通道',
    pleaseEnterReceiptAmount: '请输入收款金额',
    pleaseScanTheGoods: '请扫描商品',
    pleaseScanTheProductBarcode: '请扫描会员码或商品条码',
    paymentFailed: '收款失败',
    orderCanceled: '订单已取消',
    thereIsNoCashierYet: '还没有收银台',
    goToAdd: '去添加',
    completed: '已完成',
    refunded: '已退款',
    refundFailed: '退款失败',
    refundFailedTips:
      '请求退款失败，您的 Square 账户余额不足或者用户接受退款的卡已无效',
    refund: '退款',
    printReceipt: '打印小票',
    agreeToRefundThisOrder: '同意此订单退款',
    whetherTheGoodsAreReturnedToTheWarehouse: '商品是否退回仓库',
    pleaseCheckWhetherTheGoodsAreReturnedToTheWarehouse:
      '请勾选商品是否退回货架',
    manualRefundToBuyer: '需手动退款给买家',
    thisOrderIsPaidInCashAndNeedsToBeReturnedToTheBuyerOffline:
      '此订单现金支付，需线下将钱退给买家',
    thisOrderIsPaidInOtherAndNeedsToBeReturnedToTheBuyerOffline:
      '此订单其他支付，需线下将钱退给买家',
    thisOrderIsPaidInCombinationAndNeedsToBeReturnedToTheBuyerOffline:
      '此订单组合支付，需线下将钱退给买家',
    pleaseSelectACashier: '请选择一个收银台',
    startPrinting: '开始打印',
    failedToPrintReceipt: '打印收据失败',
    unableToConnectToTheTicketPrinterNormally: '无法正常连接到票证打印机。',
    thePhoneNumberFormatIsIncorrect: '手机号格式不正确',
    theMemberCodeFormatIsIncorrect: '会员码格式不正确',
    memberDoesNotExist: '会员不存在',
    receivablesDetails: '应收详情',
    anAdditional05UsdWillBeChargedFor:
      '在线支付实付金额小于15美元的加收0.5美元',
    refundTips: '商品不影响二次销售选择是直接退回货架，否则选择"否"',
    precautions: '注意事项',
    precautions1: '退款的金额包含商品的税费;',
    precautions2: '需手动填写商品退款数量，最大不可超过商品的购买数;',
    precautions3: '勾选全选按钮，默认代表整单退款。',
    refundQuantity: '退款数量',
    validRefundtips: '超出商品退款数最大值',
    greaterThanZero: '退款数量需要大于0',
    pleaseRefundSku: '请选择退款商品',
    refundTips1: '抱歉，此订单已于',
    refundTips2: '超过售后申请时效，无法退款。',
    goodsQuantity: '商品数量',
    refundTime: '退款时间',
    macouDed: 'Macou 抵扣(退还用户)',
    goodNo: '商品号',
    refundInfo: '退款信息',
    selecting: '已选中',
    servicesTotal: '项服务，共',
    selectService: '选择服务',
    days: '天',
    viewGuaranteeServices: '查看保障服务',
    guaranteedServiceSynchronousRefund: '保障服务同步退款',
    guaranteeService: '保障服务',
    selectTheGuaranteeServiceSynchronousRefund:
      '选中保障服务同步退款，代表当绑定保障服务的商品发生退款时，保障服务也将自动退款。',
    nameSpecification: '名称/规格',
    guaranteeName: '保障名称',
    priceValidityPeriod: '价格/有效期',
    refundableQuantity: '可退数量',
    expectedDueDate: '预计到期日',
    theRefundQuantityCannotBeLessThan1: '退款数量不能小于1',
    returnTheGoodsToTheShelf: '商品退回货架',
    orderDetails: '订单详情',
    status: '状态',
    pendingPayment: '待付款',
    cancelled: '已取消',
    orderType: '订单类型',
    buyerInformation: '买家信息',
    paymentInformation: '付款信息',
    originalPriceOfTheProduct: '商品原价',
    originalPrice: '原价',
    theTotalAmountOfOnlinePaymentIsLessThan15USDollars:
      '网上支付总额低于15美元，需加收0.5美元',
    totalSubtotalTaxSurcharge: '总计 = 小计 + 税费 + 加收金额',
    totalSubtotalTaxSurcharge1: '总计 = 小计 + 税费 + 加收金额 + 小费',
    totalSubtotalTaxSurcharge2: '总计 = 小计 + 配送费 + 税费 + 加收金额 + 小费',
    purchaseQuantity: '购买数量',
    refundedQuantity: '已退款数量',
    remainingQuantity: '剩余数量',
    operator: '操作人',
    name: '名称',
    nextStep: '下一步',
    specification: '规格',
    productNumber: '商品号',
    printRefundReceipt: '打印退款小票',
    scanProductBarcode: '扫码商品条码',
    barcodeDoesNotExist: '条码不存在',
    theQuantityEligibleForRefundIsLessThan1: '可退款数量小于1',
    theMaximumRefundableQuantityHasBeenReached: '已达到最大可退款数量',
    additionalAmount: '加收金额',
    nameAndItemNo: '名称/商品号',
    selfPickUp: '自提',
    delivery: '配送',
    unchecked: '未核对',
    checked: '已核对',
    taskTime: '任务时间',
    pickupCode: '取货码',
    numberOfPrints: '打印次数',
    numberOfPrintsTips: '成功打印一次拣货单，记为1，以此累加。',
    pickingOperator: '拣货操作人',
    shippingVerification: '发货核对',
    printPickingList: '打印拣货单',
    pickingTaskRules: '拣货任务规则',
    pickingTaskRules1:
      '在订单进入备货阶段时，系统将立即生成相应的拣货任务，以确保库存能够有序备货，提高处理效率。',
    pickingTaskRules2:
      '一旦拣货任务完成，订单将顺利过渡至下一个节点，确保流程的连贯性和高效执行。',
    orderShippingVerification: '订单发货核对',
    inStock: '备货中',
    pickQuantity: '拣货数量',
    verificationCompleted: '核对完毕',
    pickingQuantityGTPurchaseQuantity: '拣货数量不能大于购买数量，请重新输入',
    pleaseCheckRemainingProductQuantity: '核对商品数量',
    shippingGtTips: '拣货数量已满足订单，请核实商品数量，确保与订单要求一致。',
    scanWarning: '扫描警告',
    scanWarningTips: '扫描的商品未在订单中找到，请进行核实确认。',
    pleaseSelectTicketMachine: '请选择小票机',
    thereIsNoTicketYet: '没有可用的小票机',
    deliveryPickupTime: '交货/自提时间',
    awaitingPickupOrDelivery: '待取货/配送中',
    waitingForStock: '等待备货',
    deliveryTime: '配送时间',
    deliveryTime1: '交货时间',
    pickupTime: '自提时间',
    awaitingPickup: '待取货',
    delivering: '配送中',
    manualStocking: '手动备货',
    confirmReceipt: '确认收货',
    confirmDelivery: '确认送达',
    stockUpTip1: '订单预计',
    stockUpTip2:
      '开始自动备货，您可以根据自身情况提前手动备货，确认手动备货成功后，订单将进入拣货流程。',
    confirmReceiptTip: '确定此订单确认收货?',
    confirmReceiptTip1: '确认收货时，请交代用户核对订单相关信息.',
    confirmDeliveryUploadImgTip: '上传商品抵达后的图片，方便用户确认。',
    stockInsufficientTip1: '导致核对失败的原因可能有：',
    stockInsufficientTip2: '货架实际库存不足以满足订单购买数量。',
    stockInsufficientTip3:
      '商品已被补充到货架，但系统未记录补货操作，需要通知补货员在系统中进行操作。',
    outOfStockQuantity: '缺货数量',
    barcode: '条码',
    productImage: '商品图片',
    image: '图片',
    specifications: '规格',
    fullName: '全名',
    phoneNumber: '电话号码',
    selfPickupLocation: '自提地点',
    receivingAddress: '收货地址',
    unpaid: '未付款',
    tip: '小费',
    deliveryOrder: '配送订单',
    selfPickupOrders: '自提订单',
    statusTrack: '状态跟踪',
    transactionTime: '成交时间',
    stockingCompletionTime: '备货完成时间',
    startStockingTime: '开始备货时间',
    paymentSuccessTime: '支付成功时间',
    deliveryPhotos: '送达照片',
    startDeliveryTime: '开始配送时间',
    orderCancellationTime: '取消订单时间',
    deliveryFee: '配送费',
    deliveryFeeTip: '配送费 = 基础配送费 + 附加配送费',
    pickingCode: '拣货码',
    remarks: '备注',
    amountReceivedTip: '收款金额 = 总计 + Macou Use',
    taxRate: '税率',
    otherFunctions: '其他功能',
    promotion: '促销',
    modifyTheQuantityOfProducts: '修改商品数量',
    unknownProduct: '未知商品',
    pleaseEnterTheAmountOfTheProduct: '请输入商品金额',
    checkout: '结账',
    otherPayments: '其他支付',
    combinationPayment: '组合支付',
    quickInput: '快捷输入',
    giveChange: '找零',
    confirmPayment: '确认收款',
    cancelPayment: '取消收款',
    insufficientActualAmountReceived: '实收金额不足',
    use: '使用',
    paymentSuccessfully: '收款成功',
    continueToCheckout: '继续收银',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    otherPaymentTips:
      '其他支付收款仅用作记账，你需要自行向消费者确认已经支付和收款到账',
    receivables: '应收款',
    combinationPaymentMoreTips: '组合支付仅允许两种支付方式的组合',
    combinationPaymentOnePayTips1: '如果只有一种支付方式，请选择',
    combinationPaymentOnePayTips2: '现金支付方式',
    combinationPaymentOnePayTips3: '其他支付方式',
    combinationPaymentZeroPayTips: '组合支付的每一项实际收款金额不得为零。',
    paymenting: '正在收款，请稍后',
    cancelCardPayment: '如需取消卡支付，请在Reader设备上操作取消',
    other: '其他',
    combination: '组合',
    card: '卡',
    clickToViewOtherFeatures: '点击查看其他功能',
    refundMethod: '退款方式',
    productInformation: '商品信息',
    time: '时间',
    actualAmountReceived: '实际到账金额',
    thirdPartyFees: '三方手续费',
    platformCommission: '平台佣金',
    shopRepayment: '店铺还款',
    cashier1: '收银',
    returnToTheBackend: '返回后台',
    handover: '交班',
    changeShopTip: '您已切换到其他店铺，需要刷新后才能继续操作。',
    pleaseSelectTheCheckoutCounter: '请选择收银台',
    cashierName: '收银台名称',
    employee: '员工',
    alreadyOccupied: '已占用',
    alreadyOccupiedTips: '状态已占用代表此收银台其他员工正在使用',
    refundOnDutyTip: '请先选择收银台开始值班，再进行退款操作',
    refundOnDutyTip1: '点击“收银台”开启值班',
    order: '订单',
    orderInquiry: '订单查询',
    noCorrespondingOrderFound: '没有查询到对应订单',
    noOrderDetailsAvailableAtTheMoment: '暂无订单详情',
    orderFiltering: '订单筛选',
    screen: '筛选',
    distributionChannel: '销售渠道',
    orderStatus: '订单状态',
    customerInformation: '客户信息',
    orderRefundedAmount: '订单已退金额',
    viewRefundDetails: '查看退款详情',
    refundSuccess: '退款成功',
    returnQuantity: '退货数量',
    printFail: '打印失败，小票机不在线',
    noGuaranteeServiceInformationAvailableAtTheMoment: '暂无保障服务信息',
    selectRefundItem: '选择退款商品',
    guaranteeServiceRefund: '保障服务退款',
    selected: '已选',
    selectedGoods: '件商品',
    refundOptions: '退款选择',
    refundProduct: '退款商品',
    confirmRefund: '确认退款',
    forcedOffline: '强制下线',
    shiftChangeCashierConfirmation: '换班收银员确认',
    cashierTakingOverTheShift: '接班收银员',
    handoverAmount: '交班金额',
    administratorVerification: '管理员验证',
    afterSalesStatus: '售后状态',
    notRefunded: '未退款',
    partialRefund: '部分退款',
    fullRefund: '全部退款',
    writeOff: '核销',
    writeOffInputTip: '请输入核销码或扫码核销',
    confirmationOfWriteOff: '确认核销',
    winningProducts: '中奖商品',
    insufficientInventoryTip1: '抱歉，您选择的奖品目前货架库存不足，核销失败',
    insufficientInventoryTip2:
      '为确保兑奖体验，您可以选择更换一款不低于当前奖品价格的其他商品',
    replacePrizes: '更换奖品',
    writeOffSuccessful: '核销成功',
    changeProduct: '更换商品',
    availableStock: '可用库存',
    pleaseEnterTheNameOrBarcodeNumber: '请输入商品名称/商品条码/商品号',
    writeOffGoods: '核销商品',
    orderParticipateInAwardCannotRefund:
      '订单已成功参与抽奖并获得奖品，无法退款。',
    shelfStock: '货架库存',
  },
  walletManage: {
    walletManagement: '钱包管理',
    cashManagement: '现金管理',
    macouManagement: 'macou 管理',
    amountSettled: '已结算金额',
    totalSettlementAmount: '总结算金额',
    shopOwesMoney: '店铺欠款',
    settlementTime: '结算时间',
    settlementAmount: '结算金额',
    paymentTime: '支付时间',
    paymentAmount: '支付金额',
    paymentMacou: '支付macou',
    thirdPartyFees: '三方手续费',
    platformCommission: '平台抽佣',
    actualAmountReceived: '实到账金额',
    actualAmountReceivedMacou: '实到账macou',
    methodOfPayment: '支付方式',
    accountBalance: '账户余额',
    transferMoney: '转账',
    tradingTime: '交易时间',
    type: '类型',
    transfer: '转账',
    rebate: '返利',
    deduction: '抵扣',
    refund: '退款',
    transactionAmount: '交易金额',
    balance: '余额',
    remarks: '备注',
    account: '账号',
    pleaseEnterYourAccount: '请输入账号',
    pleaseEnterYourLoginPassword: '登录密码',
    cash: '现金',
    cardPayment: '卡支付',
    transferAmount: '转账金额',
    oppositeAccountNumber: '对方账号',
    transferableAmount: '可转账金额',
    enterMacouAmount: '输入macou金额',
    nextStep: '下一步',
    insufficientBalanceOfTheTransferableMacou: '可转账的macou余额不足',
    macouCreditLimitManagement: 'Macou 信用额度管理',
    evaluateYourCreditLimit: '评估您的信用额度',
    theSystemWillEvaluateYourCreditLimit: '系统将评估您的授信额度',
    evaluation: '评估',
    evaluatingInProgress: '正在评估中',
    availableCreditLimit: '可借额度',
    borrowASum: '借一笔',
    totalCreditLimit: '总授信额度',
    borrowedLimit: '已借额度',
    repayment: '去还款',
    repayment1: '还款',
    theMassHoPlatformWillProvideYouWith60DaysOfInterestFreeBorrowingForEachLoan:
      'MassHo平台将会给您每笔借款享受60天的免息。',
    evaluationRecord: '评估记录',
    activeAssessment: '主动评估',
    increaseBy: '上涨',
    reducedBy: '下跌',
    maintainUnchanged: '维持不变',
    generateIOUInformation: '生成借据信息',
    borrowingMacou: '借款macou',
    borrowableAmount: '可借款额度',
    interestFreePeriod: '免息期限为：',
    interestWillBeCalculatedBeyondTheInterestFreePeriod:
      '；超出免息期限将计算利息。',
    arrivalAccount: '到账账户',
    pleaseEnterTheCorrectAmount: '请输入正确的金额，最长 8 位数，参考：0.00',
    signatureOfCoSignatories: '共同签署人签名',
    clickOnTheSignature: '点击签名',
    iHaveReadAndAgreedToAllTheTermsOfTheLoanAgreement:
      '我已阅读并同意贷款协议所有条款，愿意承担贷款风险',
    repaymentAmountForMacou: '还款macou金额',
    repaymentAmount: '还款金额',
    loanAmount: '借款金额',
    interest: '利息',
    pleaseEnterTheLoginPassword: '请输入登录密码',
    deductionAccount: '扣款账户',
    loanPeriod: '借款时间',
    contract: '合同',
    viewContract: '查看合同',
    toBeRepaid: '待还',
    settled: '已结清',
    interestFreeDaysRemaining: '免息日还剩',
    accruedInterestFor: '已计息',
    insufficientBorrowingLimit: '可借额度不足',
    borrowAtLeast: '至少借款 0.01',
    invitationReward: '邀请奖励',
    creditLimitManagement: '额度管理',
    cashPaymentRewards: '现金支付奖励',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    combinationPayment: '组合支付',
    time: '时间',
    transactionType: '交易类型',
    productRefunds: '商品退款',
    productOrder: '商品下单',
    orderNumber: '订单编号',
    amount: '金额',
    remainingBalance: '剩余欠款',
    shopOwesMoneyTips:
      '会员用户选择现金支付时，系统无法从该订单中扣取佣金，因此该订单的佣金将被计入店铺的欠款。还款成功后，欠款金额将自动减少；当欠款数值为负时，表示平台需支付商家款项。',
    lotteryBonus: '抽奖奖金',
  },
  stockManage: {
    productName: '商品名称',
    productID: '商品ID',
    productPictures: '商品图片',
    specification: '规格',
    totalInventory: '库存总数量',
    inbound: '入库',
    outbound: '出库',
    currentInventory: '当前库存数',
    storageQuantity: '入库数量',
    quantityOfOutbound: '出库数量',
    remarks: '备注',
    specificationName: '规格名',
    inventoryType: '库存类型',
    changeTime: '变更时间',
    changeQuantity: '变更数量',
    numberOfSKUsInStock: 'SKU库存数',
    rawMaterialInventory: '原材料库存',
    nameOfRawMaterial: '原材料名称',
    rawMaterialID: '原材料ID',
    rawMaterialPicture: '原材料图片',
    unit: '单位',
    partialShortage: '（部分缺货）',
    outOfStock: '（缺货）',
    realStockNum: '商店实际总库存',
    availableStockNum: '商店可用总库存',
    inventory: '库区',
    goodsShelf: '货架',
    inventoryTitle: '库区/库位库存',
    inventoryCode: '库区编码',
    inventoryStockNum: '库区实际库存',
    inventoryAvailableStockNum: '库区可用库存',
    type: '类型',
    inventoryRecord: '库存记录',
    moveStorage: '移库',
    warehouseLocationRecords: '库位库存记录',
    inventoryResidualQuantity: '库存余量',
    operator: '操作人',
    currentStorageLocation: '当前库位',
    targetStorageLocation: '目标库位',
    shelfStockTitle: '货架库存',
    aisle: '走道',
    bay: '湾',
    thisUnitShowsTheBaseUnit: '此单位展示的是基本单位',
    shopRealTotalStockNumDesc:
      ' 商店实际库存=货架库存+库位库存(自动会将辅助单位换算成基本单位)。',
    shopAvailableStockNumDesc:
      '可用=实际库存减去其他占用的库存，即可随时发货的库存',
    rawMaterialTracking: '原材料追踪',
    storageLocationCode: '库位编码',
    shelfStockRecodsTitle: '货架库存记录',
    name: '名称',
    productNameSpecification: '商品名称/规格',
    useBasicUnitsHere: '这里使用基本单位',
    actualTotalShopStock: '商店实际总库存',
    whenTheProductIsBelowTheLowerLimitOfWarning:
      '当商品低于预警下限，会生成预警采购',
    theProcurementMeetingWillProvideRecommendedPurchaseQuantityBasedOnTheUpperLimit:
      '预警采购会根据上限给出建议采购量',
    warningProcurement: '预警采购',
    warningProcurementSelection: '预警采购选择',
    generatePurchaseOrder: '生成采购订单',
    totalShopStock: '商店实际总库存',
    availStock: '商店可用总库存',
    stockUpperLimit: '库存上限',
    stockLowerLimit: '库存下限',
    purchasingUnit: '采购单位',
    suggestedPurchaseQuantity: '建议采购数量',
    promptMessage: '提示信息',
    purchaseOrderNumber: '采购单号',
    iUnderstand: '我知道了',
    actualStoreInventory:
      '商店实际库存=货架库存+库位库存(自动会将辅助单位换算成基本单位)。',
    availableActualInventoryMinusOtherOccupiedInventory:
      '可用=实际库存减去其他占用的库存，即可随时发货的库存。',
    warehouseArea: '库区',
    shelf: '货架',
    warehouseAreaWarehouseLocationStock: '库区/库位库存',
    warehouseAreaCode: '库区编码',
    locationCode: '库位编码',
    warehouseStock: '库区实际库存',
    warehouseStockAvailability: '库区可用库存',
    transfer: '移库',
    stockRecord: '库存记录',
    warehouseStockRecords: '库区库存记录',
    purchaseReceipt: '采购入库',
    shelfReturn: '货架退回',
    shelfArrival: '到货架',
    shelfArrivalAutomatic: '到货架（自动）',
    stockBalance: '库存余量',
    currentLocation: '当前库位',
    targetLocation: '目标库位',
    pleaseSelectTheTargetWarehouseLocation: '请选择目标库位',
    shelfStock: '货架库存',
    shelfStock1: '货架实际库存',
    shelfStockAvailability: '货架可用库存',
    shelfStockRecords: '货架库存记录',
    shelfReplenishment: '货架补货',
    automaticReplenishment: '自动补货',
    goodsReturned: '商品退回',
    refundReturned: '退款退回',
    orderCancellationRefund: '订单取消退回',
    salesOutboundOffline: '销售出库(线下)',
    salesOutboundOnline: '销售出库(线上)',
    returnToWarehouse: '退回仓库',
    automaticOutbound: '自动出库',
    rawMaterialTraceability: '原材料追踪',
    returnOfRawMaterials: '原材料退回',
    setStockAlert: '设置库存预警',
    batchSetStockAlert: '批量设置库存预警',
    lockQuantity: '锁定数量',
    inTransitQuantity: '锁定数量：下单成功，订单状态还未完成。',
    quantityInTransit: '在途数量',
    theQuantityInTransit:
      '在途数量=采购订单(未复查/未到货/部分未到货)数量，自动会将包装单位换算成基本单位。',
    damage: '损坏',
    expired: '过期',
    initialQuantity: '初始数量',
    lockedQuantityTheProductsInTheOrderHaveNotReachedThePointOfDeductingAvailableInventory:
      '锁定数量：下单成功，订单状态还未完成。',
    rechargeCardName: '充值卡名称',
    recommendedPurchaseQuantityFormula:
      '建议采购量公式：<br />1.固定：(上限-商店可用总库存-在途数量)/包装单位，取整。<br />2.AI：[（过去采购周期+进货时间+备货时间未退款的订单商品数）/（过去这些天的营业天数）*（未来采购周期的营业天数）-商店可用总库存]/包装单位，向上取整。<br />3.订单：(进货时间+备货时间内的数量之和)/包装单位，向上取整。',
    fixed: '固定',
    ai: 'AI',
    calculateTheSalesVolumeOfGoodsBasedOnThePastProcurementCycle:
      '统计过去采购周期+进货时间+备货时间的商品销售量。',
    purchasingCycle: '采购周期',
    purchaseTime: '进货时间',
    stockingTime: '备货时间',
    days: '天',
    actualInventoryOfTheStore:
      '商店实际库存=货架库存+库位库存(自动会将包装单位换算成基本单位)。',
    inventoryLockDetails: '库存锁定详情',
    inventoryInTransitDetails: '库存在途详情',
    documentNumber: '单据编号',
    documentType: '单据类型',
    orderStatus: '订单状态',
    onlineSalesOrder: '线上销售订单',
    offlineSalesOrder: '线下销售订单',
    purchaseOrder: '采购订单',
    cashAPrize: '兑奖',
  },
  purchaseManage: {
    purchaseNumber: '采购单号',
    notReviewed: '未复查',
    notDelivered: '未到货',
    partialArrived: '部分到货',
    allArrived: '全部到货',
    manuallyComplete: '手动完成',
    createPurchaseOrder: '创建采购订单',
    totalPurchaseQuantity: '采购总量',
    remark: '备注',
    createEmployee: '创建员',
    review: '复查',
    finish: '完结',
    newPurchaseOrder: '新增采购订单',
    barcode: '条码',
    productType: '商品类型',
    selectProducts: '选商品',
    selectRawMaterials: '选原材料',
    product: '商品',
    rawMaterials: '原材料',
    scanProductOrRawMaterialBarcode: '扫描商品或原材料条码',
    alreadyExistsInTheList: '已存在列表中',
    productName: '商品名称',
    rawMaterialName: '原材料名称',
    productClassification: '商品分类',
    classificationOfRawMaterials: '原材料分类',
    productNumber: '商品号',
    completedPurchaseOrder: '完结采购订单',
    reminderPurchaseOrdersCompleted:
      '提醒：采购订单完结：将不能后续入库操作，未到货的商品将取消，已到货商品记为实际库存。',
    quantityReceived: '已入库数量',
    pleaseCarefullyCheckTheReceivedGoods:
      '请仔细核对到货商品，复核成功后，将无法修改采购订单。',
    pleaseCarefullyCheckThePurchasedGoods:
      '请仔细核对采购商品，复查成功后，将无法修改采购订单',
    ifTheRawMaterialHasAPackagingUnit:
      '如果原材料启用了包装单位，则展示的是包装单位',
    type: '类型',
    scanBarcode: '扫描条码',
    rechargeCard: '充值卡',
    selectRechargeCard: '选充值卡',
    name: '名称',
    rechargeCardName: '充值卡名称',
  },
  warehouseManage: {
    shopWarehouse: '店铺仓库',
    warehouseArea: '库区',
    location: '库位',
    pleaseSelectAShopWarehouseOrAnyWarehouseArea: '请选择店铺仓库或者任意库区',
    addNewWarehouseArea: '新增库区',
    editWarehouseArea: '编辑库区',
    deleteWarehouseArea: '删除库区',
    warehouseAreaCode: '库区编码',
    oneTo10Characters: '1 到 10 位',
    pleaseSelectAnyWarehouseAreaOrAnyLocation: '请选择任意库区或者任意库位',
    unableToDeleteTheWarehouseArea: '无法删除库区，请先删除库区下所有库位',
    confirmDeletionOfSelectedWarehouseArea: '确认删除选中库区',
    addNewWarehouseLocation: '新增库位',
    editWarehouseLocation: '编辑库位',
    deleteWarehouseLocation: '删除库位',
    locationCode: '库位编码',
    confirmDeletionOfSelectedWarehouseLocation: '确认删除选中库位',
    product: '商品',
    rawMaterials: '原材料',
    pleaseSelectTheWarehouseLocationFirst: '请先选择库位',
    productId: '商品ID',
    rawMaterialId: '原材料ID',
    productImage: '商品图片',
    image: '图片',
    productName: '商品名称',
    rawMaterialName: '原材料名称',
    specifications: '规格',
    unit: '单位',
    stockInTheWarehouseArea: '库区库存',
    warehouseLocation: '库位',
    type: '类型',
    inboundNumber: '入库单号',
    purchaseNumber: '采购单号',
    purchaseReceipt: '采购入库',
    shelfReturn: '货架退回',
    time: '时间',
    today: '今天',
    nearly7Days: '近7天',
    nearly30Days: '近30天',
    nearly90Days: '近90天',
    threeMonthsAgo: '三个月前',
    totalReceipt: '入库总量',
    createEmployee: '创建员',
    purchaseOrderInformation: '采购订单信息',
    selectPurchaseOrder: '选择采购订单',
    noContent: '暂无内容',
    purchaseTime: '采购时间',
    purchaseCreator: '采购创建员',
    searchPurchaseOrderNumber: '搜索采购单号',
    pleaseEnterThePurchaseOrderNumber: '请输入采购订单号',
    notDelivered: '未到货',
    partialDelivery: '部分到货',
    totalPurchaseQuantity: '采购总量',
    receiptCount: '已入库数',
    name: '名称',
    ifTheProductHasEnabledPackagingUnits:
      '如果商品启用了包装单位，则展示的是包装单位。',
    purchaseQuantity: '采购数量',
    quantityToBereceived: '待入库数量',
    actualQuantityReceived: '实际到货数量',
    barcode: '条码',
    productNumber: '商品号',
    isRawMaterials: '是否原材料',
    warehouseAreaLocation: '入库库区/库位',
    shelfArrival: '到货架',
    shelfArrivalAuto: '到货架(自动)',
    outboundNumber: '出库单号',
    outboundDate: '出库日期',
    sourceWarehouseAreaLocation: '来源库区/库位',
    targetShelf: '目标货架',
    inboundDate: '入库日期',
    inboundCreator: '入库创建员',
    purchaseDate: '采购日期',
    inboundQuantity: '入库数量',
    sourceShelf: '来源货架',
    inboundProductDetails: '入库产品明细',
    quantityOfOutbound: '出库数量',
    rechargeCard: '充值卡',
    createOutboundOrder: '新建出库单',
    damage: '损坏',
    expired: '过期',
    outboundType: '出库类型',
    newOutboundOrder: '新增出库单',
    batchTransfer: '批量转移',
    pleaseSelectTheProduct: '请选择商品',
    selectedProduct: '已选商品',
    targetLocation: '目标库位',
    item: '项',
    batchTransferSuccessfulTips: '批量转移成功，将商品搬移到新的正确库位',
    pleaseSelectTheTargetWarehouseLocation: '请选择目标库位',
    batchSettingsAllArrival: '批量设置全部到货',
    batchSettingWarehouseAreaLocation: '批量设置库区/库位',
    batchSettingsAllArrivalTip: '确定将所有已勾选商品设置为全部到货？',
    batchSettingsAllArrivalTip1: '设置成功后，实际到货数量=待入库数量',
  },
  shelfManage: {
    newArea: '新增区域',
    newShelf: '新增货架号',
    editShelf: '编辑货架号',
    nameOfArea: '区域名称',
    shelfNumberName: '货架号名称',
    creationTime: '创建时间',
    lengthShouldBeBetween1And25: '长度1到25之间',
    relationGood: '关联商品',
    areaDetail: '区域详情',
    goodNo: '商品号',
    areaLocation: '区域位置',
    shelfLocation: '所在货架位置',
    shelfLocal: '货架位置',
    shelfTotalArea: '货架总区域',
    away: '走道',
    bay: '湾',
    addAisle: '新增走道',
    addBay: '新增湾',
    pleaseSelectTheOverallAreaOfTheShelfOrAnyAisle:
      '请选择货架总区域或者任意走道',
    oneTo10Characters: '1 到 10 位',
    pleaseChooseAnyAisleOrAnyBay: '请选择任意走道或者任意湾',
    editAisle: '编辑走道',
    editBay: '编辑湾',
    unableToDeleteTheAisle: '无法删除走道，请先删除走道下所有湾',
    aisleNumber: '走道编号',
    deleteAisle: '删除走道',
    confirmToDeleteTheSelectedAisle: '确认删除选中走道',
    bayNumber: '湾编号',
    deleteBay: '删除湾',
    confirmDeletingSelectedBay: '确认删除选中湾',
    pleaseSelectTheBayFirst: '请先选择湾',
    productNumber: '商品号',
    image: '图片',
    productName: '商品名称',
    specifications: '规格',
    price: '售价',
    unit: '单位',
    shelfStock: '货架库存',
    productType: '商品类型',
    product: '商品',
    rawMaterialProduct: '原材料商品',
    putOnTheShelf: '上货架',
    placeTheGoodsInAnOrderlyMannerOnTheShelvesForCustomersToChoose:
      '将商品有序摆放到货架上，让客户挑选。',
    numberOfShelves: '上架数量',
    warehouseStockAvailable: '库区可用库存数',
    more: '更多',
    shopStock: '商店可用总库存数',
    shelfReplenishment: '货架补货',
    returnToWarehouse: '退回仓库',
    returnQuantity: '退回数量',
    targetWarehouseArea: '目标库区',
    productId: '商品ID',
    warningCondition: '预警状况',
    aboveWarningLimit: '高于预警上限',
    belowWarningLimit: '低于预警下限',
    theUnitDisplayedHereIsTheBaseUnit: '此单位展示的是基本单位',
    lowerLimit: '下限',
    upperLimit: '上限',
    whetherAlertIsSetOrNot: '有无设置预警',
    productNameSpecification: '商品名称/规格',
    setShelfAlert: '设置货架预警',
    batchSet: '批量设置',
    batchSetShelfAlert: '批量设置货架预警',
    batchAlertValueSetting: '批量预警值设置',
    rawMaterialProductsAreAffectedByRawMaterialsAndAreNotDisplayedOnTheShelvesAsAWarning:
      '原材料商品受到原材料影响，不在货架上展示预警。',
    warehouseStock: '库区实际库存数',
    addGoods: '库区实际库存数',
    storeStock: '商店实际总库存数',
    enableTheInitialQuantityOnTheShelf: '启用货架上的初始数量',
    whenYouEnableTheInitialQuantityOfShelves:
      '当你启用货架期初数量时，这适用于那些已经摆放在货架上的商品。启用成功后，系统将增加额外的库存数量。',
    initialQuantity: '初始数量',
    thisUnitDisplaysThePackagingUnit: '此单位展示的是包装单位',
    name: '名称',
    parValue: '面值',
    rechargeCard: '充值卡',
    placeTheRechargeCardsNeatlyOnTheShelvesForCustomersToChooseFrom:
      '将充值卡有序摆放到货架上，让客户挑选。',
    refuelingCard: '加油充值卡',
    universalRechargeCard: '通用充值卡',
    type: '类型',
    rechargeCardOnShelves: '充值卡上架',
    editRechargeCard: '编辑充值卡',
    rechargeCardDetails: '充值卡详情',
    theShelfPositionCannotBeEmpty: '货架位置不能为空。',
    theShelfQuantityCannotBeEmpty: '上架数量不能为空。',
    returnToWarehouseCannotBeEmpty: '退回仓库不能为空。',
    theProductWasNotFoundInTheWarehouse:
      '仓库中没有找到这个商品。请先将商品添加到仓库，然后再退回仓库。',
    theProductWasNotFoundInTheWarehouse1:
      '仓库中没有找到这个商品。请先将商品添加到仓库，然后再补货',
    batchTransfer: '批量转移',
    pleaseSelectTheProduct: '请选择商品',
    selectedProduct: '已选商品',
    targetLocation: '目标货架',
    item: '项',
    batchTransferSuccessfulTips: '批量转移成功，将商品搬移到新的正确货架',
    pleaseSelectTheTargetWarehouseLocation: '请选择目标货架',
    remove: '移除',
    removeTip: '移除提示',
    removeTip1:
      '商品存在部分规格未选中，操作成功后，系统自动将此商品下所有的规格都移除',
    removeSuccess: '移除成功',
    removeNotAllowTip: '该商品已上架线上店铺，不允许移除',
    batchPutOnTheShelf: '批量上架',
    batchPutOnTheShelfTip:
      '适用于上架同一个货架位置的商品，填写上架数量直接批量上架。',
    editPutOnTheShelf: '编辑上架',
    editPutOnTheShelfTip: '指定商品进行编辑上架，一次只能上架一件商品。',
    putOnTheShelfMethod: '上架方式',
    onSale: '上架',
    numberOfShelvesIncorrect: '上架数量有误',
    removeTip2: '充值卡有货架库存，不允许移除。',
    removeTip3: '商品部分规格有货架库存，不允许移除。',
  },
  guaranteeManage: {
    guaranteeType: '保障类型',
    establishAGoodTypeOfGuaranteeSoThatYouCanEasilyViewVariousGuaranteeServicesAndFacilitateManagement:
      '建立一个良好的保障类型，以便您可以轻松查看各个保障服务，方便管理。',
    associatedGuaranteeServices: '关联保障服务',
    addGuaranteeType: '新增保障类型',
    guaranteeTypeName: '保障类型名称',
    pleaseEnterTheTypeOfGuarantee: '请输入保障类型',
    oneTo50Characters: '1 到 50 位字符',
    editGuaranteeType: '编辑保障类型',
    guaranteeName: '保障名称',
    ifYourProductSupportsGuaranteeServices:
      '如果您的商品支持保障服务，则填写保障服务的相关信息。',
    pleaseEnterTheName: '请输入名称',
    price: 'Price',
    validityPeriodDays: '有效期(天)',
    serviceIntroduction: '服务介绍',
    describeTheServiceToLetUsersKnowWhatGuaranteesAreIncludedInThisService:
      '对服务进行描述，让用户知道这个服务有哪些保障内容',
    oneTo5Characters: '1 to 5 characters',
  },
  settingsManage: {
    automaticOutboundNotification: '自动出库通知',
    whenTheInventoryOnTheShelvesIsSoldOut:
      '当货架上的库存已经售罄时，如果有顾客拿着商品前来结账，系统会允许完成结账操作。在这一过程中，系统会自动将相应的商品出库，并即时通知下方设置的工作人员，提醒他们核实库存情况，确保库存信息准确无误。',
  },
  notifyManage: {
    automaticOutboundNotification: '自动出库通知',
    verified: '已核实',
    notVerified: '未核实',
    quantityOfOutbound: '出库数量',
    notice: '通知',
    subject: '主题',
    automaticUpdateNotificationForProductOutbound: '商品自动出库更新通知',
    dearAdministrator:
      '尊敬的管理员，希望您一切安好。我们的自动系统检测到了最近一个需要您关注的库存事件。',
    eventDetails: '事件详情',
    triggerShelfStockDepleted: '触发：货架库存耗尽。',
    requiredOperation: '所需操作',
    verifyInventoryPleaseCarefullyCheckTheCurrentInventoryToEnsureAccuracy:
      '核实库存：请仔细检查当前库存，确保准确性。将系统记录与实际库存进行交叉核对。',
    adjustmentsWhenNecessaryIfAnyDiscrepanciesAreFound:
      '必要时进行调整：如果发现任何差异，请立即在系统中进行必要的调整，以反映正确的库存水平。',
    confirmAndIgnoreIfTheInventoryHasBeenConfirmedAndNoDiscrepanciesAreFound:
      '确认并忽略：如果库存经过确认，未发现任何差异，请忽略此通知。您的确认确保我们的记录是最新的。',
    thankYouVeryMuchForYourPromptAttentionToThisMatter:
      '非常感谢您对此事的迅速关注。',
    thankYouForYourDedicationToMaintainingAccurateInventoryRecords:
      '感谢您致力于保持准确的库存记录。',
  },
  cashierManagement: {
    pleaseSelectCashier: '请选择收银台',
    unbindWarning: '解绑警告',
    pleaseEnsureCashierNotUse: '请确保该收银台未被使用',
    afterSuccessfulUnbindingCashierWillNotAbleFunctionProperly:
      '解绑成功后，收银台将不能正常使用',
  },
  data: {
    time: '时间',
    today: '今天',
    yesterday: '昨天',
    nearly7Days: '近7天',
    nearly30Days: '近30天',
    receiptStructure: '收款构成',
    type: '类型',
    turnover: '营业额',
    turnoverTip:
      '营业额=实际收支+Macou 收支，订单包含线上订单，线下订单(扣除退款订单)',
    actualIncomeAndExpenditure: '实际收支',
    actualIncomeAndExpenditureTip:
      '实际收支：统计订单（总计）- 三方手续费 - 平台佣金（$）  - 店铺还款 - 退款金额',
    macouIncomeAndExpenditure: 'Macou收支',
    macouIncomeAndExpenditureTip:
      'Macou收支：订单抵扣Macou部分- 平台佣金（Macou） - 商家返利Macou -  订单退款Macou (商家实际收到的Macou)',
    comprehensiveStatistics: '综合统计',
    refundStatistics: '退款统计',
    refundStatisticsTip1: '退款统计：按商家同意退款时间统计。',
    refundStatisticsTip2:
      '1. 退款订单数：退款的订单数，如果一个订单分开2次退款，记为2。',
    refundStatisticsTip3:
      '2. 退款金额：统计退还给用户的金额（商家实际得到的金额+店铺还款+一定比例手续费）。',
    refundStatisticsTip4:
      '3. 退款Macou ：统计需要退还给用户的Macou（商家实际得到的Macou）。',
    refundStatisticsTip5: '4.  Macou 返利：统计用户退回给商家的Macou。',
    numberOfRefundedOrders: '退款订单数',
    orderRefundAmount: '订单退款金额',
    orderRefundAmountTip: '按商家同意退款的时间统计退款金额',
    refundMacou: '退款Macou',
    macouRebate: 'Macou 返利',
    details: '详情',
    monthlyReport: '月报',
    customTime: '自定义时间',
    date: '日期',
    tip: '小费',
    taxes: '税费',
    thirdPartyFees: '三方手续费',
    platformCommission: '平台佣金',
    platformCommissionTip1:
      '仅记录实际抽取的美元部分。平台佣金（$）= 销售订单佣金',
    platformCommissionTip2: '平台佣金（Macou）= 销售订单佣金',
    cash: '现金',
    cardPayment: '卡支付',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    orderPaymentAmount: '订单支付金额',
    orderPaymentAmountTip: '周期内订单总计',
    total: '合计',
    orderType: '订单类型',
    offlineOrder: '线下订单',
    onlineOrder: '线上订单',
    payType: '支付方式',
    orderNumber: '订单编号',
    paymentTime: '付款时间',
    orderPaymentMacou: '订单支付Macou',
    orderTotal: '订单总计',
    actualAmountReceived: '实际到账金额',
    actualAmountReceivedTip:
      '实际到账金额=订单总计 - 第三方手续费 - 平台抽佣（$） - 店铺还款',
    actualReceiptMacou: '实际到账Macou',
    operator: '操作人',
    transactionType: '交易类型',
    productRefunds: '商品退款',
    productOrder: '商品下单',
    totalData: '合计数据',
    totalReceipts: '收款合计',
    querying: '查询中',
    tradingTime: '交易时间',
    incomeAndExpenditureType: '收支类型',
    expenditure: '支出',
    income: '收入',
    orderTime: '下单时间',
    refundTime: '退款时间',
    refundAmount: '退款金额',
    refundMacouTip: '用户退款成功后，需要将Macou退还',
    refundMethod: '退款方式',
    macouRebateTip: '用户退款成功后，Macou返利需要退还给商家',
    totalTip: '所选周期内各字段的总合计',
    shopRepayment: '店铺还款',
    shopRepaymentTip: '店铺还款：偿还店铺所欠的金额',
    refundAmountTip: '退款金额=商家实际收到的金额+手续费+店铺还款',
  },
  handover: {
    workingHours: '上班时长',
    hour: '小时',
    minute: '分钟',
    counter: '收银台',
    previousCashier: '前班收银员',
    collectionOverview: '收款总览',
    paymentDetails: '缴款明细',
    collectionOverviewStatistics1: '总交易',
    collectionOverviewStatistics2: '笔，收入',
    collectionOverviewStatistics3: '笔，退款',
    collectionOverviewStatistics4: '笔',
    paymentMethod: '结算方式',
    onDutyCollectionAmount: '当班收款金额',
    cash: '现金',
    cashSalesAmount: '现金销售金额',
    cashRefund: '现金退款',
    cardPayment: '卡支付',
    cardPaymentSalesAmount: '卡支付销售金额',
    cardPaymentRefund: '卡支付退款',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    total: '总计',
    statisticalDimension: '统计维度',
    statisticalDimensionTip1: '1.前班现金结余：换班时交接的现金。',
    statisticalDimensionTip2: '2.当班现金收款金额：现金销售金额-现金退款。',
    statisticalDimensionTip3: '3.当班领取现金：当班期间申请领取的现金。',
    statisticalDimensionTip4:
      '4.当班缴纳金额：当班期间上缴现金的总金额(实缴金额+待审核金额)。',
    statisticalDimensionTip5:
      '5.累计未缴纳金额：前班现金结余+当班现金收款金额+当班领用现金 - 当班缴纳金额。',
    cashBalanceInTheCashBox: '钱箱现金结余',
    amount: '金额',
    cashBalanceOfPreviousShift: '前班现金结余',
    cashCollectionAmountOnDuty: '当班现金收款金额',
    receiveCashOnDuty: '当班领取现金',
    onDutyPaymentAmount: '当班缴纳金额',
    paidInAmount: '实缴金额',
    pendingAuditAmount: '待审核金额',
    accumulatedUnpaidAmount: '累计未缴金额',
    handoverRecord: '交班记录',
    cashRequest: '领取现金',
    submitCash: '上缴现金',
    handover: '交班',
    printShiftHandoverReceipt: '打印交班小票',
    applyToReceiveCash: '申请领取现金',
    amountToBeCollected: '领取金额',
    remark: '备注',
    confirmTheSubmissionOfCash: '确认上缴现金?',
    confirmTheSubmissionOfCashTip: '现金上缴后立即生效，无法撤回',
    theAmountThatCanBePaidThisTime: '本次可上缴金额',
    submissionAmount: '上缴金额',
    confirmSubmission: '确认上缴',
    applicationSuccessful: '申请成功',
    submittedSuccessfully: '上缴成功',
    theAmountSubmittedShouldBeGreaterThan0: '上缴金额应大于0',
    submittedAmountOutOfRange: '上缴金额不能大于可上缴金额',
    unauditedAmountTip: '存在待审核金额，请联系管理员审核后再交班',
    handoverReminder: '交班提示',
    shiftChange: '换班',
    endShift: '结束班次',
    handoverAmount: '交班金额',
    cashierTakingOverTheShift: '接班收银员',
    nextStep: '下一步',
    shiftChangeCashierLogin: '换班收银员登录',
    previousStep: '上一步',
    endShiftTip: '当前班次工作结束，无法再进行开单操作',
    haveSubmmitAmountTip: '还有未上缴的现金，请先上缴现金',
    goHandInCash: '去上缴现金>',
    logout: '退出',
    handoverNumber: '交班编号',
    cashier: '收银员',
    onDutyCashier: '当班收银员',
    workShift: '上班时间',
    closingTime: '下班时间',
    endOfShift: '是否结束班次',
    startShift: '开启班次',
    pendingReview: '待审核',
    reviewed: '已审核',
    reviewRejected: '审核拒绝',
    toExamine: '审核',
    refuse: '拒绝',
    documentNumber: '单据编号',
    submissionTime: '上缴时间',
    cashDeposit: '现金缴款',
    refuseTip: '确认拒绝选中数据？',
    refuseTip1: '此操作将无法撤销。',
    examineTip: '共',
    examineTip1: '条单据，成功',
    examineTip2: '条，失败',
    examineTip3: '条',
    reasonForFailure: '失败原因',
    payableAmount: '应缴金额',
    remainingUnpaid: '剩余未缴',
    eventTimeRecord: '事件时间记录',
    reviewTime: '审核时间',
    applicationTime: '申请时间',
    cashBalance: '现金结余',
    applicationAmount: '申请金额',
    reprint: '重新打印',
    screen: '筛选',
    realIncome: '实际收入',
    onDutyTime: '当班时间',
    shiftHandoverTimeScreening: '交班时间筛选',
    confirmRejectionOfApplication: '确认拒绝申请？',
    noHandoverRecord: '没有交班记录',
    noHandoverDetailsAvailableAtTheMoment: '暂无交班详情',
    printingSuccessful: '打印成功',
    cashCollectionRecord: '领取现金记录',
    cashSubmissionRecord: '上缴现金记录',
    forcedOfflineTime: '强制下线时间',
  },
  marketing: {
    eventEntrance: '活动入口',
    increaseSalesVolume: '提升销量',
    enterTheEvent: '进入活动',
    luckyDraw: '幸运抽奖',
    luckyDrawTip:
      '参与平台的转盘活动，可以提高店铺流量，给粉丝带来福利，提高粉丝忠诚度',
    sweepstakes: '抽奖活动',
    rule: '规则',
    activityTraffic: '活动流量',
    activityTrafficTip: '通过奖品区进入店铺的，计为一个访客数',
    turnover: '营业额',
    turnoverTip: '通过奖品区进入店铺并进行下单的营业额（订单总计+Macou Use）',
    numberOfOrders: '订单数',
    numberOfOrdersTip: '通过奖品区进入店铺并下单成功的订单数',
    activityCost: '活动成本',
    activityCostTip: '兑换成功商品数量*成本价',
    awardAreaLocation: '奖区位置',
    inProgress: '进行中',
    ended: '已结束',
    activityProducts: '活动商品',
    activityProductsTip: '商品选择仅限于货架上的商品',
    participationTime: '参与时间',
    remainingNumberOfPrizes: '奖品剩余数',
    winningRecord: '中奖记录',
    newActivities: '新增活动',
    selectProduct: '选择商品',
    activityInventory: '活动库存',
    activityInventoryTip: '活动库存与商品售卖库存共用，请合理分配',
    redemptionValidityPeriod: '兑奖有效期',
    redemptionValidityPeriodTip:
      '奖品自中奖之日起计算，有效期为3至365天，超出有效期用户将无法兑换',
    endTimeTip: '活动到达该时间后将自动结束活动',
    awardAreaLocationTip: '根据商品售价，将商品自动归类到相应的奖区',
    awardAreaLocationTip1:
      '根据商品售价，自动归类到相应奖区。如售价已在其他地方修改，此处仍显示活动创建时的售价。',
    days: '天',
    availableStock: '可用库存',
    productName: '商品名称',
    specifications: '规格',
    product: '商品',
    price: '售价',
    modifyTheSelectedProduct: '修改已选商品',
    inventoryExceeds: '库存超出',
    minimumActiveInventoryIs1: '活动库存最小为1',
    activityRules: '活动规则',
    activityTip1: '不同商品的价格将自动分配到不同的奖区。',
    activityTip2: '参与活动的商品价值必须符合市场上规定的参与条件范围。',
    activityTip3: '用户中奖后，必须兑换奖品，否则将受到平台严厉惩罚。',
    activityTip4: '参与活动后，无法撤回。',
    activityTip5: '奖区说明：',
    awardAreaName: '奖区名称',
    productValue: '商品价值',
    eventDetails: '活动详情',
    winningRecords: '中奖记录',
    remainingPrizesTip: '奖品剩余数 = 活动库存 - 已兑换',
    statusTrack: '状态跟踪',
    notReceived: '未领取',
    redeemed: '已兑换',
    expired: '已失效',
    account: '账号',
    winningInformation: '中奖信息',
    winningTime: '中奖时间',
    collectionTime: '领取时间',
    activitySubmitTip: '活动一经提交，无法撤销',
  },
}
