import request from '@/utils/request'

/**
 * 获取奖区Label
 */
export const getAwardZoneLabel = () => {
  return request({
    url: '/shop/activity/getAwardZoneLabel',
    method: 'GET',
  })
}

/**
 * 抽奖活动统计
 */
export const sweepstakesStatistic = () => {
  return request({
    url: '/shop/activity/sweepstakesStatistic',
    method: 'GET',
  })
}

/**
 * 抽奖活动列表
 */
export const sweepstakesList = (params) => {
  return request({
    url: '/shop/activity/sweepstakesList',
    method: 'GET',
    params,
  })
}

/**
 * 货架商品列表
 */
export const shelfProducts = (params) => {
  return request({
    url: '/shop/activity/shelfProducts',
    method: 'GET',
    params,
  })
}

/**
 * 获取奖区名称
 */
export const getAwardZone = (price) => {
  return request({
    url: `/shop/activity/getAwardZone/${price}`,
    method: 'GET',
  })
}

/**
 * 新增活动
 */
export const addActivities = (data) => {
  return request({
    url: '/shop/activity/addActivities',
    method: 'POST',
    data,
  })
}

/**
 * 获取活动详情
 */
export const eventDetails = (id) => {
  return request({
    url: `/shop/activity/eventDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取活动状态跟踪
 */
export const getStateTracking = (id) => {
  return request({
    url: `/shop/activity/getStateTracking/${id}`,
    method: 'GET',
  })
}

/**
 * 获取中奖记录列表
 */
export const getWinningRecord = (params) => {
  return request({
    url: '/shop/activity/getWinningRecord',
    method: 'GET',
    params,
  })
}

/**
 * 根据核销码核销商品信息（Ipad）
 */
export const getWriteOffInfoForIpad = (code) => {
  return request({
    url: `/shop/activity/getWriteOffInfoForIpad/${code}`,
    method: 'GET',
  })
}

/**
 * 更换的商品列表(ipad)
 */
export const productListForIpad = (params) => {
  return request({
    url: '/shop/activity/productListForIpad',
    method: 'GET',
    params,
  })
}

/**
 * 确认核销（ipad）
 */
export const confirmWriteOffForIpad = (data) => {
  return request({
    url: '/shop/activity/confirmWriteOffForIpad',
    method: 'POST',
    data,
  })
}

/**
 * 更换商品（ipad）
 */
export const changeProductForIpad = (data) => {
  return request({
    url: '/shop/activity/changeProductForIpad',
    method: 'POST',
    data,
  })
}

/**
 * 活动数量统计
 */
export const activityCount = (params) => {
  return request({
    url: '/shop/activity/activityCount',
    method: 'GET',
    params,
  })
}
