import request from '@/utils/request'

/**
 * 获取线上订单列表
 * @returns 线上订单列表列表
 */
export const getOnlineOrderList = (params) => {
  return request({
    url: '/shop/onlineOrder/getOnlineOrderList',
    method: 'GET',
    params,
  })
}

/**
 * 获取线上订单详情
 */
export const getOnlineOrderDetail = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineOrderDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上订单详情 Ipad
 */
export const getOnlineOrderDetailForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineOrderDetailForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 手动备货
 */
export const manualStocking = (orderId) => {
  return request({
    url: `/shop/onlineOrder/manualStocking/${orderId}`,
    method: 'POST',
  })
}

/**
 * 确认收货/确认送达
 */
export const confirmReceipt = (data) => {
  return request({
    url: '/shop/onlineOrder/confirmReceipt',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取线上订单 Macou 抵扣详情列表
 */
export const getMacouUseList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouUseList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单 Macou 返币详情列表
 */
export const getMacouRebateList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouRebateList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单退款 Macou 抵扣详情列表
 */
export const getRefundMacouUseList = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundMacouUseList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单退款 Macou 返利详情列表
 */
export const getRefundMacouRebateList = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundMacouRebateList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单 Macou 抵扣详情列表(ipad)
 */
export const getMacouUseListForIpad = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouUseListForIpad/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单 Macou 返利详情列表(ipad)
 */
export const getMacouRebateListForIpad = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouRebateListForIpad/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单退款 Macou 抵扣详情列表(ipad)
 */
export const getRefundMacouUseListForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundMacouUseListForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单退款 Macou 返利详情列表(ipad)
 */
export const getRefundMacouRebateListForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundMacouRebateListForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 根据订单ID获取线上订单Sku信息
 */
export const getSkuInfoByOrderId = (id) => {
  return request({
    url: `/shop/onlineOrder/getSkuInfoByOrderId/${id}`,
    method: 'GET',
  })
}

/**
 * 根据订单ID获取订单Sku信息(IPad)
 */
export const getSkuInfoByOrderIdForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getSkuInfoByOrderIdForIpad/${id}`,
    method: 'GET',
  })
}
/**
 * 线上订单申请退款
 */
export const requestRefund = (data) => {
  return request({
    url: '/shop/onlineOrder/requestRefund',
    method: 'POST',
    data,
  })
}
/**
 * 线上订单申请退款 Ipad
 */
export const requestRefundForIpad = (data) => {
  return request({
    url: '/shop/onlineOrder/requestRefundForIpad',
    method: 'POST',
    data,
  })
}

/**
 * 获取保障服务列表
 */
export const guaranteeServiceList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/guaranteeServiceList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 获取保障服务列表(ipad)
 */
export const guaranteeServiceListForIpad = (orderId) => {
  return request({
    url: `/shop/onlineOrder/guaranteeServiceListForIpad/${orderId}`,
    method: 'GET',
  })
}

/**
 * 订单状态跟踪
 */
export const getStatusTracking = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getStatusTracking/${orderId}`,
    method: 'GET',
  })
}
/**
 * 订单状态跟踪 Ipad
 */
export const getStatusTrackingForIpad = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getStatusTrackingForIpad/${orderId}`,
    method: 'GET',
  })
}

/**
 * 判断订单时间是否过期
 */
export const checkOrderTimeExpire = (orderId) => {
  return request({
    url: `/shop/onlineOrder/checkOrderTimeExpire/${orderId}`,
    method: 'GET',
  })
}

/**
 * 判断订单时间是否过期 Ipad
 */
export const checkOrderTimeExpireForIpad = (orderId) => {
  return request({
    url: `/shop/onlineOrder/checkOrderTimeExpireForIpad/${orderId}`,
    method: 'GET',
  })
}

/**
 * 发起退款后查看线上订单状态是否成功
 */
export const getRefundOrder = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundOrder/${id}`,
    method: 'GET',
  })
}

/**
 * 发起退款后查看线上订单状态是否成功 Ipad
 */
export const getRefundOrderForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundOrderForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 获取可用并且带有打票机的收银台
 */
export const getAvailableAndPrintCashierCounterList = () => {
  return request({
    url: '/shop/onlineOrder/getAvailableAndPrintCashierCounterList',
    method: 'GET',
  })
}

/**
 * 打印线上订单小票
 */
export const printOnlineTicket = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineTicket',
    method: 'POST',
    data,
  })
}

/**
 * 打印线上订单小票 Ipad
 */
export const printOnlineTicketForIpad = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineTicketForIpad',
    method: 'POST',
    data,
  })
}

/**
 * 打印线上退款订单小票
 */
export const printOnlineRefundTicket = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineRefundTicket',
    method: 'POST',
    data,
  })
}

/**
 * 打印线上退款订单小票 Ipad
 */
export const printOnlineRefundTicketForIpad = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineRefundTicketForIpad',
    method: 'POST',
    data,
  })
}

/**
 * 根据退款ID获取退款详情
 */
export const getRefundDesById = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 根据退款ID获取退款详情(ipad)
 */
export const getRefundDesByIdForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundDesByIdForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上订单的提示备货时间
 */
export const getOnlineOrderPickingTime = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineOrderPickingTime/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上收款金额信息
 */
export const getOnlineAmountCollected = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineAmountCollected/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上实际收款金额详情
 */
export const getOnlineActualAmountReceived = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineActualAmountReceived/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上实际收款金额详情 Ipad
 */
export const getOnlineActualAmountReceivedForIpad = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineActualAmountReceivedForIpad/${id}`,
    method: 'GET',
  })
}

/**
 * 获取登录账号的收银台信息
 */
export const getAccountCounter = () => {
  return request({
    url: '/shop/onlineOrder/getAccountCounter',
    method: 'GET',
  })
}

/**
 * 校验订单是否参与抽奖(IPad)
 */
export const checkOrderIfPrizeDraw = (id) => {
  return request({
    url: `/shop/onlineOrder/checkOrderIfPrizeDraw/${id}`,
    method: 'GET',
  })
}
