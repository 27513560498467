import ShopLayoutView from '@/layout/shop/LayoutView'
import { shopDefaultIndex } from '.'

/**
 * 注意：
 *  1. 不要乱修改 path，如果要修改，就全局搜一下
 *  2. resFlag 属性的作用：第一次动态生成路由后，如果切换店铺，需要把 resFlag = true 的都清理掉，重新生成，不然多个店铺之间切换会出现本不该属于其的资源权限。
 */

/**
 * 独立路由器
 */
export const shopIndependentRouters = [
  {
    path: '/shop/shopConfig',
    name: 'ShopConfigView',
    component: () => import('@/views/shop/shopManage/components/shopConfig'),
    meta: {
      flag: 'shop',
      title: 'shopSetting',
    },
  },
  {
    path: '/shop/checkout',
    name: 'CheckoutLayoutView',
    component: () =>
      import('@/views/shop/orderManage/offline-order-info/checkout/LayoutView'),
    meta: {
      flag: 'shop',
      title: 'checkoutLayout',
    },
    children: [
      {
        path: '/shop/counter',
        name: 'CheckoutViewView',
        component: () =>
          import(
            '@/views/shop/orderManage/offline-order-info/checkout/component/CheckoutView'
          ),
        meta: {
          flag: 'shop',
          title: 'offlineCheckout',
        },
      },
      {
        path: '/shop/handover',
        name: 'HandoverView',
        component: () =>
          import(
            '@/views/shop/orderManage/offline-order-info/checkout/component/HandoverView'
          ),
        meta: {
          flag: 'shop',
          title: 'handover',
        },
      },
      {
        path: '/shop/order',
        name: 'OrderView',
        component: () =>
          import(
            '@/views/shop/orderManage/offline-order-info/checkout/component/OrderView'
          ),
        meta: {
          flag: 'shop',
          title: 'order',
        },
      },
      {
        path: '/shop/writeOff',
        name: 'WriteOffView',
        component: () =>
          import(
            '@/views/shop/orderManage/offline-order-info/checkout/component/WriteOffView'
          ),
        meta: {
          flag: 'shop',
          title: 'writeOff',
        },
      },
    ],
  },
]

/**
 * 私有路由表
 */
export const shopPrivateRoutes = {
  // #region 首页
  ShopLayoutIndexView: {
    path: shopDefaultIndex,
    name: 'ShopLayoutIndexView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      sortFlag: 1,
    },
    children: [],
  },
  // 首页
  IndexView: {
    path: '/shop/index',
    name: 'IndexView',
    p: 'ShopLayoutIndexView',
    component: () => import('@/views/shop/index/IndexView'),
    meta: {
      title: 'shopIndex',
      icon: 'icon-massho-b-shouye',
      flag: 'shop',
      sortFlag: 1,
      resFlag: true,
    },
    children: [
      {
        path: '/shop/analysisReport',
        name: 'AnalysisReportView',
        component: () => import('@/views/shop/index/AnalysisReportView'),
        meta: {
          title: 'analysisReport',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 商品管理
  ShopLayoutGoodsListInfoView: {
    path: '/shop/goodsManage',
    name: 'ShopLayoutGoodsListInfoView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'goodsManage',
      icon: 'icon-massho-b-shangpinguanli',
      sortFlag: 2,
    },
    children: [],
  },
  // 商品信息
  GoodsListInfoView: {
    path: '/shop/goodsManage/goodsListInfo',
    name: 'GoodsListInfoView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import('@/views/shop/goods-manage/goods-list-info/GoodsListInfoView'),
    meta: {
      flag: 'shop',
      title: 'goodsManageGoodsListInfo',
      icon: 'icon-massho-b-shangpinguanli',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/goodsManage/goodsListInfo/addGoods',
        name: 'GoodsListInfoAddGoodsView',
        component: () =>
          import('@/views/shop/goods-manage/goods-list-info/page/AddGoodsView'),
        meta: {
          title: 'releaseGoods',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/goodsListInfo/editGoods',
        name: 'GoodsListInfoEditGoodsView',
        component: () =>
          import(
            '@/views/shop/goods-manage/goods-list-info/page/EditGoodsView'
          ),
        meta: {
          title: 'releaseGoods1',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/goodsListInfo/viewGoods',
        name: 'GoodsListInfoViewGoodsView',
        component: () =>
          import(
            '@/views/shop/goods-manage/goods-list-info/page/ViewGoodsView'
          ),
        meta: {
          title: 'releaseGoods2',
          flag: 'shop',
        },
      },
    ],
  },
  // 商品分类
  GoodsClassifyView: {
    path: '/shop/goodsManage/goodsClassify',
    name: 'GoodsClassifyView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import('@/views/shop/goods-manage/goods-classify/GoodsClassifyView'),
    meta: {
      title: 'goodsClassify',
      icon: 'icon-massho-b-shangpinfenlei',
      flag: 'shop',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/goodsManage/goodsClassify/goodsClassifyAdd',
        name: 'GoodsClassifyAddView',
        component: () =>
          import(
            '@/views/shop/goods-manage/goods-classify/add/GoodsClassifyAddView'
          ),
        meta: {
          title: 'goodsClassifyAdd',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/goodsClassify/ClassifyDsc',
        name: 'GoodsClassifyDscView',
        component: () =>
          import(
            '@/views/shop/goods-manage/goods-classify/classify-dsc/ClassifyDscView'
          ),
        meta: {
          title: 'goodsClassifyDsc',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/goodsClassify/edit',
        name: 'GoodsClassifyEditView',
        component: () =>
          import('@/views/shop/goods-manage/goods-classify/edit/EditView'),
        meta: {
          title: 'goodsClassifyAdd1',
          flag: 'shop',
        },
      },
    ],
  },
  // 原材料管理
  RawMaterialView: {
    path: '/shop/goodsManage/rawMaterial',
    name: 'RawMaterialView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import('@/views/shop/goods-manage/raw-material/RawMaterialView'),
    meta: {
      title: 'rawMaterial',
      icon: 'icon-massho-b-yuancailiao',
      flag: 'shop',
      resFlag: true,
    },
  },
  // 原材料分类
  RawMaterialClassifyView: {
    path: '/shop/goodsManage/rawMaterialClassify',
    name: 'RawMaterialClassifyView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import(
        '@/views/shop/goods-manage/raw-material-classify/RawMaterialClassifyView'
      ),
    meta: {
      title: 'rawMaterialClassify',
      icon: 'icon-massho-b-shangpinfenlei',
      flag: 'shop',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/goodsManage/rawMaterialClassify/rawMaterialClassifyAdd',
        name: 'RawMaterialClassifyAddView',
        component: () =>
          import(
            '@/views/shop/goods-manage/raw-material-classify/add/RawMaterialClassifyAddView'
          ),
        meta: {
          title: 'rawMaterialClassifyAdd',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/rawMaterialClassify/ClassifyDsc',
        name: 'RawMaterialClassifyDscView',
        component: () =>
          import(
            '@/views/shop/goods-manage/raw-material-classify/classify-dsc/ClassifyDscView'
          ),
        meta: {
          title: 'rawMaterialClassifyDsc',
          flag: 'shop',
        },
      },
      {
        path: '/shop/goodsManage/rawMaterialClassify/edit',
        name: 'RawMaterialClassifyEditView',
        component: () =>
          import(
            '@/views/shop/goods-manage/raw-material-classify/edit/EditView'
          ),
        meta: {
          title: 'rawMaterialClassifyAdd1',
          flag: 'shop',
        },
      },
    ],
  },
  // 计量单位
  MeasureUnitView: {
    path: '/shop/goodsManage/MeasureUnit',
    name: 'MeasureUnitView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import('@/views/shop/goods-manage/measure-unit/MeasureUnitView'),
    meta: {
      title: 'rawMeasureUnit',
      icon: 'icon-massho-b-navicon-jldwsz',
      flag: 'shop',
      resFlag: true,
    },
  },
  // 充值卡服务
  FuelCardInfoView: {
    path: '/shop/goodsManage/fuelCardInfo',
    name: 'FuelCardInfoView',
    p: 'ShopLayoutGoodsListInfoView',
    component: () =>
      import('@/views/shop/goods-manage/fuel-card-info/FuelCardInfoView.vue'),
    meta: {
      title: 'rechargeCardService',
      icon: 'icon-massho-b-chongzhiqia',
      flag: 'shop',
      resFlag: true,
    },
  },
  // #endregion
  // #region 在线商品管理
  ShopLayoutPushGoodsView: {
    path: '/shop/onlineGoodsManage',
    name: 'ShopLayoutPushGoodsView',
    component: ShopLayoutView,
    meta: {
      title: 'onlineGoodsManage',
      icon: 'icon-massho-b-dianshang',
      flag: 'shop',
      sortFlag: 3,
    },
    children: [],
  },
  // 在线商品
  PushGoodsView: {
    path: '/shop/onlineGoodsManage/pushGoodsView',
    name: 'PushGoodsView',
    p: 'ShopLayoutPushGoodsView',
    component: () =>
      import('@/views/shop/online-goods-manage/push-goods/PushGoodsView'),
    meta: {
      title: 'onlineProducts',
      icon: 'icon-massho-b-fabu',
      flag: 'shop',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/onlineGoodsManage/pushGoods/releaseGoodsView',
        name: 'PushGoodsReleaseGoodsView',
        component: () =>
          import(
            '@/views/shop/online-goods-manage/push-goods/release-goods/ReleaseGoodsView'
          ),
        meta: {
          title: 'pushGoods',
          flag: 'shop',
        },
      },
      {
        path: '/shop/onlineGoodsManage/pushGoods/editReleaseGoods',
        name: 'EditReleaseGoodsView',
        component: () =>
          import(
            '@/views/shop/online-goods-manage/push-goods/release-goods/EditReleaseGoodsView.vue'
          ),
        meta: {
          title: 'pushGoods1',
          flag: 'shop',
        },
      },
      {
        path: '/shop/onlineGoodsManage/pushGoods/viewReleaseGoods',
        name: 'ViewReleaseGoodsView',
        component: () =>
          import(
            '@/views/shop/online-goods-manage/push-goods/release-goods/ViewReleaseGoodsView.vue'
          ),
        meta: {
          title: 'pushGoods2',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 店铺管理
  ShopLayoutShopSettingView: {
    path: '/shop/shopManage',
    name: 'ShopLayoutShopSettingView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'shopManage',
      icon: 'icon-massho-b-dianpuguanli',
      sortFlag: 4,
    },
    children: [],
  },
  // 店铺设置
  ShopSettingView: {
    path: '/shop/shopManage/shopSetting',
    name: 'ShopSettingView',
    p: 'ShopLayoutShopSettingView',
    component: () => import('@/views/shop/shopManage/shopSetting'),
    meta: {
      flag: 'shop',
      title: 'shopSetting',
      icon: 'icon-massho-b-dianpushezhi',
      resFlag: true,
    },
  },
  // 收银台管理
  CashierManagementView: {
    path: '/shop/shopManage/cashierManagement',
    name: 'CashierManagementView',
    p: 'ShopLayoutShopSettingView',
    component: () => import('@/views/shop/shopManage/CashierManagement'),
    meta: {
      flag: 'shop',
      title: 'cashierManagement',
      icon: 'icon-massho-b-12',
      resFlag: true,
    },
  },
  // 物流管理
  LogisticsManagementView: {
    path: '/shop/shopManage/logisticsManagement',
    name: 'LogisticsManagementView',
    p: 'ShopLayoutShopSettingView',
    component: () => import('@/views/shop/shopManage/LogisticsManagementView'),
    meta: {
      flag: 'shop',
      title: 'logisticsManagement',
      icon: 'icon-massho-b-wuliuwuliuyunshu',
      resFlag: true,
    },
  },
  // 打印机管理
  PrintersManagementView: {
    path: '/shop/shopManage/printersManagement',
    name: 'PrintersManagementView',
    p: 'ShopLayoutShopSettingView',
    component: () => import('@/views/shop/shopManage/PrintersManagementView'),
    meta: {
      flag: 'shop',
      title: 'printersManagement',
      icon: 'icon-massho-b-icon-',
      resFlag: true,
    },
  },
  // #endregion
  // #region 订单管理
  ShopLayoutOfflineOrderInfoView: {
    path: '/shop/orderManage',
    name: 'ShopLayoutOfflineOrderInfoView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'OrderManage',
      icon: 'icon-massho-b-dingdanguanli',
      sortFlag: 5,
    },
    children: [],
  },
  // 线下订单
  OfflineOrderInfoView: {
    path: '/shop/orderManage/offlineOrderInfo',
    name: 'OfflineOrderInfoView',
    p: 'ShopLayoutOfflineOrderInfoView',
    component: () =>
      import('@/views/shop/orderManage/offline-order-info/offlineOrderInfo'),
    meta: {
      flag: 'shop',
      title: 'OfflineOrderInfo',
      icon: 'icon-massho-b-xianxiadingdan',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/orderManage/offlineOrderInfo/offlineOrderDes',
        name: 'OfflineOrderDesView',
        component: () =>
          import(
            '@/views/shop/orderManage/offline-order-info/offline-order-des/OfflineOrderDesView'
          ),
        meta: {
          flag: 'shop',
          title: 'offlineOrderDes',
        },
      },
      // {
      //   path: '/shop/orderManage/offlineOrderInfo/checkout',
      //   name: 'CheckoutViewView',
      //   component: () =>
      //     import(
      //       '@/views/shop/orderManage/offline-order-info/checkout/CheckoutView'
      //     ),
      //   meta: {
      //     flag: 'shop',
      //     title: 'offlineCheckout',
      //   },
      // },
    ],
  },
  // 线上订单
  OnlineOrderInfoView: {
    path: '/shop/orderManage/onlineOrderInfo',
    name: 'OnlineOrderInfoView',
    p: 'ShopLayoutOfflineOrderInfoView',
    component: () =>
      import('@/views/shop/orderManage/online-order-info/onlineOrderInfo'),
    meta: {
      flag: 'shop',
      title: 'OnlineOrderInfo',
      icon: 'icon-massho-b-xianshangdingdan',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/orderManage/onlineOrderInfo/onlineOrderDes',
        name: 'OnlineOrderDesView',
        component: () =>
          import(
            '@/views/shop/orderManage/online-order-info/online-order-des/OnlineOrderDesView'
          ),
        meta: {
          flag: 'shop',
          title: 'onlineOrderDes',
        },
      },
    ],
  },
  // 拣货任务
  PickingTaskInfoView: {
    path: '/shop/orderManage/pickingTaskInfo',
    name: 'PickingTaskInfoView',
    p: 'ShopLayoutOfflineOrderInfoView',
    component: () =>
      import('@/views/shop/orderManage/picking-task-info/pickingTaskInfo'),
    meta: {
      flag: 'shop',
      title: 'PickingTaskInfo',
      icon: 'icon-massho-b-jianhuorenwu',
      resFlag: true,
    },
    children: [],
  },
  // #endregion
  // #region 钱包管理
  ShopLayoutWalletInfoView: {
    path: '/shop/walletManage',
    name: 'ShopLayoutWalletInfoView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'walletManage',
      icon: 'icon-massho-b-qianbao',
      sortFlag: 6,
    },
    children: [],
  },
  // 钱包管理
  WalletInfoView: {
    path: '/shop/walletManage/walletInfo',
    name: 'WalletInfoView',
    p: 'ShopLayoutWalletInfoView',
    component: () => import('@/views/shop/walletManage/walletInfo'),
    meta: {
      flag: 'shop',
      title: 'walletInfo',
      icon: 'icon-massho-b-qianbao1',
      resFlag: true,
    },
  },
  // 额度管理
  CreditLimitManagementView: {
    path: '/shop/walletManage/creditLimitManagement',
    name: 'CreditLimitManagementView',
    p: 'ShopLayoutWalletInfoView',
    component: () =>
      import('@/views/shop/walletManage/CreditLimitManagementView'),
    meta: {
      flag: 'shop',
      title: 'creditLimitManagement',
      icon: 'icon-massho-b-edu',
      resFlag: true,
    },
  },
  // #endregion
  // #region 库存管理
  ShopLayoutGoodsStockView: {
    path: '/shop/stockManage',
    name: 'ShopLayoutGoodsStockView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'StockManage',
      icon: 'icon-massho-b-kucun',
      sortFlag: 7,
    },
    children: [],
  },
  // 商品库存
  GoodsStockView: {
    path: '/shop/stockManage/goodsStock',
    name: 'GoodsStockView',
    p: 'ShopLayoutGoodsStockView',
    component: () =>
      import('@/views/shop/stockManage/goodsStock/GoodsStockView.vue'),
    meta: {
      flag: 'shop',
      title: 'goodsStock',
      icon: 'icon-massho-b-cangkukucun',
      resFlag: true,
    },
  },
  // 原材料库存
  RawStockView: {
    path: '/shop/stockManage/rawStock',
    name: 'RawStockView',
    p: 'ShopLayoutGoodsStockView',
    component: () =>
      import('@/views/shop/stockManage/rawStock/RawStockView.vue'),
    meta: {
      flag: 'shop',
      title: 'rawmaterialStock',
      icon: 'icon-massho-b-kucunfenxi',
      resFlag: true,
    },
  },
  // 充值卡库存
  RechargeCardStockView: {
    path: '/shop/stockManage/rechargeCardStock',
    name: 'RechargeCardStockView',
    p: 'ShopLayoutGoodsStockView',
    component: () =>
      import(
        '@/views/shop/stockManage/rechargeCardStock/RechargeCardStockView.vue'
      ),
    meta: {
      flag: 'shop',
      title: 'rechargeCardStock',
      icon: 'icon-massho-b-chongzhiqia',
      resFlag: true,
    },
  },
  // 库存预警
  StockWarningView: {
    path: '/shop/stockManage/stockWarning',
    name: 'StockWarningView',
    p: 'ShopLayoutGoodsStockView',
    component: () =>
      import('@/views/shop/stockManage/stockWarning/StockWarningView.vue'),
    meta: {
      flag: 'shop',
      title: 'inventoryWarning',
      icon: 'icon-massho-b-yujing',
      resFlag: true,
    },
  },
  // 库存预警设置
  StockWarningSettingsView: {
    path: '/shop/stockManage/stockWarningSettings',
    name: 'StockWarningSettingsView',
    p: 'ShopLayoutGoodsStockView',
    component: () =>
      import(
        '@/views/shop/stockManage/stockWarningSettings/StockWarningSettingsView.vue'
      ),
    meta: {
      flag: 'shop',
      title: 'inventoryWarningSettings',
      icon: 'icon-massho-b-hetongyujing',
      resFlag: true,
    },
  },
  // #endregion
  // #region 采购管理
  ShopLayoutPurchaseView: {
    path: '/shop/purchaseManage',
    name: 'ShopLayoutPurchaseView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'purchaseManage',
      icon: 'icon-massho-b-caigou',
      sortFlag: 8,
    },
    children: [],
  },
  // 采购订单
  PurchaseOrderView: {
    path: '/shop/purchaseManage/purchaseOrder',
    name: 'PurchaseOrderView',
    p: 'ShopLayoutPurchaseView',
    component: () =>
      import('@/views/shop/purchaseManage/purchaseOrder/PurchaseOrderView.vue'),
    meta: {
      flag: 'shop',
      title: 'purchaseOrder',
      icon: 'icon-massho-b-zhengchangcaigoudingdan',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/purchaseManage/purchaseOrder/purchaseOperDetails',
        name: 'PurchaseOperDetailsView',
        component: () =>
          import(
            '@/views/shop/purchaseManage/purchaseOrder/page/PurchaseOperDetailsView.vue'
          ),
        meta: {
          title: 'purchaseOperDetails',
          flag: 'shop',
        },
      },
      {
        path: '/shop/purchaseManage/purchaseOrder/purchaseOperReview',
        name: 'PurchaseOperReviewView',
        component: () =>
          import(
            '@/views/shop/purchaseManage/purchaseOrder/page/PurchaseOperReviewView.vue'
          ),
        meta: {
          title: 'purchaseOperDetails1',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 仓库管理
  ShopLayoutWarehouseView: {
    path: '/shop/warehouseManage',
    name: 'ShopLayoutWarehouseView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'warehouseManage',
      icon: 'icon-massho-b-cangkuguanli',
      sortFlag: 9,
    },
    children: [],
  },
  // 仓库
  WarehouseView: {
    path: '/shop/warehouseManage/warehouse',
    name: 'WarehouseView',
    p: 'ShopLayoutWarehouseView',
    component: () =>
      import('@/views/shop/warehouseManage/warehouse/WarehouseView.vue'),
    meta: {
      flag: 'shop',
      title: 'warehouse',
      icon: 'icon-massho-b-cangkucangchu-xianxing',
      resFlag: true,
    },
  },
  // 入库
  InboundView: {
    path: '/shop/warehouseManage/inbound',
    name: 'InboundView',
    p: 'ShopLayoutWarehouseView',
    component: () =>
      import('@/views/shop/warehouseManage/inbound/InboundView.vue'),
    meta: {
      flag: 'shop',
      title: 'inbound',
      icon: 'icon-massho-b-ruku',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/warehouseManage/inbound/inboundDetails',
        name: 'InboundDetailsView',
        component: () =>
          import(
            '@/views/shop/warehouseManage/inbound/page/InboundDetailsView.vue'
          ),
        meta: {
          title: 'inboundDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // 出库
  OutboundView: {
    path: '/shop/warehouseManage/outbound',
    name: 'OutboundView',
    p: 'ShopLayoutWarehouseView',
    component: () =>
      import('@/views/shop/warehouseManage/outbound/OutboundView.vue'),
    meta: {
      flag: 'shop',
      title: 'outbound',
      icon: 'icon-massho-b-chuku',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/warehouseManage/outbound/outboundDetails',
        name: 'OutboundDetailsView',
        component: () =>
          import(
            '@/views/shop/warehouseManage/outbound/page/OutboundDetailsView.vue'
          ),
        meta: {
          title: 'outboundDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 货架管理
  ShopLayoutShelfView: {
    path: '/shop/shelfManage',
    name: 'ShopLayoutShelfView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'shelfManage',
      icon: 'icon-massho-b-shangpinhuojiaguanli',
      sortFlag: 10,
    },
    children: [],
  },
  // 货架
  ShelfView: {
    path: '/shop/shelfManage/shelf',
    name: 'ShelfView',
    p: 'ShopLayoutShelfView',
    component: () => import('@/views/shop/shelfManage/shelf/ShelfView.vue'),
    meta: {
      flag: 'shop',
      title: 'shelf',
      icon: 'icon-massho-b-liaojia',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/shelfManage/shelf/putToShelf',
        name: 'PutToShelfView',
        component: () =>
          import('@/views/shop/shelfManage/shelf/page/PutToShelfView.vue'),
        meta: {
          title: 'putOnTheShelf',
          flag: 'shop',
        },
      },
      {
        path: '/shop/shelfManage/shelf/operShelfGoods',
        name: 'OperShelfGoodsView',
        component: () =>
          import('@/views/shop/shelfManage/shelf/page/OperShelfGoodsView.vue'),
        meta: {
          title: 'operShelfGoods',
          flag: 'shop',
        },
      },
      {
        path: '/shop/shelfManage/shelf/viewShelfGoods',
        name: 'ViewShelfGoodsView',
        component: () =>
          import('@/views/shop/shelfManage/shelf/page/ViewShelfGoodsView.vue'),
        meta: {
          title: 'operShelfGoods1',
          flag: 'shop',
        },
      },
    ],
  },
  // 货架预警
  ShelfWarningView: {
    path: '/shop/shelfManage/shelfWarning',
    name: 'ShelfWarningView',
    p: 'ShopLayoutShelfView',
    component: () =>
      import('@/views/shop/shelfManage/shelfWarning/ShelfWarningView.vue'),
    meta: {
      flag: 'shop',
      title: 'shelfWarning',
      icon: 'icon-massho-b-yujing',
      resFlag: true,
    },
  },
  // 货架预警设置
  ShelfWarningSettingsView: {
    path: '/shop/shelfManage/shelfWarningSettings',
    name: 'ShelfWarningSettingsView',
    p: 'ShopLayoutShelfView',
    component: () =>
      import(
        '@/views/shop/shelfManage/shelfWarningSettings/ShelfWarningSettingsView.vue'
      ),
    meta: {
      flag: 'shop',
      title: 'shelfWarningSettings',
      icon: 'icon-massho-b-hetongyujing',
      resFlag: true,
    },
  },
  // 货架出库
  ShelfOutboundView: {
    path: '/shop/shelfManage/shelfOutbound',
    name: 'ShelfOutboundView',
    p: 'ShopLayoutShelfView',
    component: () =>
      import('@/views/shop/shelfManage/shelfOutbound/ShelfOutboundView.vue'),
    meta: {
      flag: 'shop',
      title: 'shelfOutbound',
      icon: 'icon-massho-b-chuku',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/shelfManage/shelfOutbound/outboundDetails',
        name: 'ShelfOutboundDetailsView',
        component: () =>
          import(
            '@/views/shop/shelfManage/shelfOutbound/page/ShelfOutboundDetailsView.vue'
          ),
        meta: {
          title: 'outboundDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 保障服务
  ShopLayoutGuaranteeServiceView: {
    path: '/shop/guaranteeService',
    name: 'ShopLayoutGuaranteeServiceView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'guaranteeService',
      icon: 'icon-massho-b-zhuanyebaozhangfuwu',
      sortFlag: 11,
    },
    children: [],
  },
  // 保障模板
  GuaranteeTemplateView: {
    path: '/shop/guaranteeService/guaranteeTemplate',
    name: 'GuaranteeTemplateView',
    p: 'ShopLayoutGuaranteeServiceView',
    component: () =>
      import(
        '@/views/shop/guaranteeService/guaranteeTemplate/GuaranteeTemplateView'
      ),
    meta: {
      flag: 'shop',
      title: 'guaranteeTemplate',
      icon: 'icon-massho-b-yingpingmoban',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/guaranteeService/guaranteeTemplate/operGuaranteeTemplate',
        name: 'OperGuaranteeTemplateView',
        component: () =>
          import(
            '@/views/shop/guaranteeService/guaranteeTemplate/page/OperGuaranteeTemplateView'
          ),
        meta: {
          title: 'operGuaranteeTemplate',
          flag: 'shop',
        },
      },
      {
        path: '/shop/guaranteeService/guaranteeTemplate/editGuaranteeTemplate',
        name: 'EditGuaranteeTemplateView',
        component: () =>
          import(
            '@/views/shop/guaranteeService/guaranteeTemplate/page/EditGuaranteeTemplateView.vue'
          ),
        meta: {
          title: 'operGuaranteeTemplate1',
          flag: 'shop',
        },
      },
      {
        path: '/shop/guaranteeService/guaranteeTemplate/viewGuaranteeTemplate',
        name: 'ViewGuaranteeTemplateView',
        component: () =>
          import(
            '@/views/shop/guaranteeService/guaranteeTemplate/page/ViewGuaranteeTemplateView.vue'
          ),
        meta: {
          title: 'operGuaranteeTemplate2',
          flag: 'shop',
        },
      },
    ],
  },
  // 保障类型
  GuaranteeTypeView: {
    path: '/shop/guaranteeService/guaranteeType',
    name: 'GuaranteeTypeView',
    p: 'ShopLayoutGuaranteeServiceView',
    component: () =>
      import('@/views/shop/guaranteeService/guaranteeType/GuaranteeTypeView'),
    meta: {
      flag: 'shop',
      title: 'guaranteeType',
      icon: 'icon-massho-b-fenlei',
      resFlag: true,
    },
  },
  // #endregion
  // #region 设置
  ShopLayoutSettingsView: {
    path: '/shop/settings',
    name: 'ShopLayoutSettingsView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'settings',
      icon: 'icon-massho-b-31shezhi',
      sortFlag: 12,
    },
    children: [],
  },
  // 通知设置
  NotificationSettingsView: {
    path: '/shop/settings/notificationSettings',
    name: 'NotificationSettingsView',
    p: 'ShopLayoutSettingsView',
    component: () =>
      import(
        '@/views/shop/settings/notificationSettings/NotificationSettingsView'
      ),
    meta: {
      flag: 'shop',
      title: 'notificationSettings',
      icon: 'icon-massho-b-tongzhi',
      resFlag: true,
    },
  },
  // #endregion
  // #region 数据
  ShopLayoutDataView: {
    path: '/shop/data',
    name: 'ShopLayoutDataView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'data',
      icon: 'icon-massho-b-shuju',
      sortFlag: 13,
    },
    children: [],
  },
  // 经营报表
  OperatingStatementView: {
    path: '/shop/data/operatingStatement',
    name: 'OperatingStatementView',
    p: 'ShopLayoutDataView',
    component: () =>
      import('@/views/shop/data/operating-statement/OperatingStatementView'),
    meta: {
      flag: 'shop',
      title: 'operatingStatement',
      icon: 'icon-massho-b-jingyingbaobiao',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/data/operatingStatement/summaryTable',
        name: 'SummaryTableView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/SummaryTableView.vue'
          ),
        meta: {
          title: 'summaryTable',
          flag: 'shop',
        },
      },
      {
        path: '/shop/data/operatingStatement/orderDeductionDetails',
        name: 'OrderDeductionDetailsView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/OrderDeductionDetailsView.vue'
          ),
        meta: {
          title: 'orderDeductionDetails',
          flag: 'shop',
        },
      },
      {
        path: '/shop/data/operatingStatement/paymentDetails',
        name: 'PaymentDetailsView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/PaymentDetailsView.vue'
          ),
        meta: {
          title: 'paymentDetails',
          flag: 'shop',
        },
      },
      {
        path: '/shop/data/operatingStatement/tipDetails',
        name: 'TipDetailsView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/TipDetailsView.vue'
          ),
        meta: {
          title: 'tipDetails',
          flag: 'shop',
        },
      },
      {
        path: '/shop/data/operatingStatement/taxesDetails',
        name: 'TaxesDetailsView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/TaxesDetailsView.vue'
          ),
        meta: {
          title: 'taxesDetails',
          flag: 'shop',
        },
      },
      {
        path: '/shop/data/operatingStatement/refundDetails',
        name: 'RefundDetailsView',
        component: () =>
          import(
            '@/views/shop/data/operating-statement/page/RefundDetailsView.vue'
          ),
        meta: {
          title: 'refundDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // 交班记录
  HandoverRecordView: {
    path: '/shop/data/handoverRecord',
    name: 'HandoverRecordView',
    p: 'ShopLayoutDataView',
    component: () =>
      import('@/views/shop/data/handover-record/HandoverRecordView'),
    meta: {
      flag: 'shop',
      title: 'handoverRecord',
      icon: 'icon-massho-b-jiaoban',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/data/handoverRecord/handoverDetails',
        name: 'HandoverDetailsView',
        component: () =>
          import(
            '@/views/shop/data/handover-record/page/HandoverDetailsView.vue'
          ),
        meta: {
          title: 'handoverDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 财务管理
  FinancialManagementView: {
    path: '/shop/financial',
    name: 'FinancialManagementView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'financial',
      icon: 'icon-massho-b-caiwuguanli',
      sortFlag: 14,
    },
    children: [],
  },
  // 现金缴款单
  CashPaymentReceiptView: {
    path: '/shop/financial/cashPaymentReceipt',
    name: 'CashPaymentReceiptView',
    p: 'FinancialManagementView',
    component: () =>
      import(
        '@/views/shop/financial-management/cash-payment-receipt/CashPaymentReceiptView'
      ),
    meta: {
      flag: 'shop',
      title: 'cashPaymentReceipt',
      icon: 'icon-massho-b-shouyinyuanjiaokuandan',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/financial/cashPaymentReceipt/cashPaymentReceiptDetails',
        name: 'CashPaymentReceiptDetailsView',
        component: () =>
          import(
            '@/views/shop/financial-management/cash-payment-receipt/page/CashPaymentReceiptDetailsView.vue'
          ),
        meta: {
          title: 'cashPaymentReceiptDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // 现金领取单
  CashCollectionFormView: {
    path: '/shop/financial/cashCollectionForm',
    name: 'CashCollectionFormView',
    p: 'FinancialManagementView',
    component: () =>
      import(
        '@/views/shop/financial-management/cash-collection-form/CashCollectionFormView'
      ),
    meta: {
      flag: 'shop',
      title: 'cashCollectionForm',
      icon: 'icon-massho-b-icon-xian-23',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/financial/cashCollectionForm/cashCollectionFormDetails',
        name: 'CashCollectionFormDetailsView',
        component: () =>
          import(
            '@/views/shop/financial-management/cash-collection-form/page/CashCollectionFormDetailsView.vue'
          ),
        meta: {
          title: 'cashCollectionFormDetails',
          flag: 'shop',
        },
      },
    ],
  },
  // #endregion
  // #region 营销管理
  MarketingManagementView: {
    path: '/shop/marketing',
    name: 'MarketingManagementView',
    component: ShopLayoutView,
    meta: {
      flag: 'shop',
      title: 'marketing',
      icon: 'icon-massho-b-yingxiaoguanli',
      sortFlag: 14,
    },
    children: [],
  },
  // 营销活动
  MarketingActivitiesView: {
    path: '/shop/marketing/marketingActivities',
    name: 'MarketingActivitiesView',
    p: 'MarketingManagementView',
    component: () =>
      import(
        '@/views/shop/marketing-management/marketing-activities/MarketingActivitiesView'
      ),
    meta: {
      flag: 'shop',
      title: 'marketingActivities',
      icon: 'icon-massho-b-yingxiaozhongxin',
      resFlag: true,
    },
    children: [
      {
        path: '/shop/marketing/marketingActivities/sweepstakes',
        name: 'SweepstakesView',
        component: () =>
          import(
            '@/views/shop/marketing-management/marketing-activities/page/SweepstakesView.vue'
          ),
        meta: {
          title: 'sweepstakes',
          flag: 'shop',
        },
        children: [
          {
            path: '/shop/marketing/marketingActivities/activityDetails',
            name: 'ActivityDetailsView',
            component: () =>
              import(
                '@/views/shop/marketing-management/marketing-activities/page/ActivityDetailsView.vue'
              ),
            meta: {
              title: 'activityDetails',
              flag: 'shop',
            },
          },
          {
            path: '/shop/marketing/marketingActivities/winningRecords',
            name: 'WinningRecordsView',
            component: () =>
              import(
                '@/views/shop/marketing-management/marketing-activities/page/WinningRecordsView.vue'
              ),
            meta: {
              title: 'winningRecords',
              flag: 'shop',
            },
          },
        ],
      },
    ],
  },
  // #endregion
}
