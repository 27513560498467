import {
  getOnlineOrderList,
  getOnlineOrderDetail,
  getOnlineOrderDetailForIpad,
  manualStocking,
  confirmReceipt,
  getMacouUseList,
  getMacouRebateList,
  getRefundMacouUseList,
  getRefundMacouRebateList,
  getMacouUseListForIpad,
  getMacouRebateListForIpad,
  getRefundMacouUseListForIpad,
  getRefundMacouRebateListForIpad,
  getSkuInfoByOrderId,
  getSkuInfoByOrderIdForIpad,
  requestRefund,
  requestRefundForIpad,
  guaranteeServiceList,
  guaranteeServiceListForIpad,
  getStatusTracking,
  getStatusTrackingForIpad,
  checkOrderTimeExpire,
  checkOrderTimeExpireForIpad,
  getRefundOrder,
  getRefundOrderForIpad,
  getAvailableAndPrintCashierCounterList,
  printOnlineTicket,
  printOnlineTicketForIpad,
  printOnlineRefundTicket,
  printOnlineRefundTicketForIpad,
  getRefundDesById,
  getRefundDesByIdForIpad,
  getOnlineOrderPickingTime,
  getOnlineAmountCollected,
  getOnlineActualAmountReceived,
  getOnlineActualAmountReceivedForIpad,
  getAccountCounter,
  checkOrderIfPrizeDraw,
} from '@/api/shop/onlineOrder'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getOnlineOrderList(context, params) {
      return await getOnlineOrderList(params)
    },
    async getOnlineOrderDetail(context, id) {
      return await getOnlineOrderDetail(id)
    },
    async getOnlineOrderDetailForIpad(context, id) {
      return await getOnlineOrderDetailForIpad(id)
    },
    async manualStocking(context, orderId) {
      return await manualStocking(orderId)
    },
    async confirmReceipt(context, data) {
      return await confirmReceipt(data)
    },
    async getMacouUseList(context, orderId) {
      return await getMacouUseList(orderId)
    },
    async getMacouRebateList(context, orderId) {
      return await getMacouRebateList(orderId)
    },
    async getRefundMacouUseList(context, id) {
      return await getRefundMacouUseList(id)
    },
    async getRefundMacouRebateList(context, id) {
      return await getRefundMacouRebateList(id)
    },
    async getMacouUseListForIpad(context, orderId) {
      return await getMacouUseListForIpad(orderId)
    },
    async getMacouRebateListForIpad(context, orderId) {
      return await getMacouRebateListForIpad(orderId)
    },
    async getRefundMacouUseListForIpad(context, id) {
      return await getRefundMacouUseListForIpad(id)
    },
    async getRefundMacouRebateListForIpad(context, id) {
      return await getRefundMacouRebateListForIpad(id)
    },
    async getSkuInfoByOrderId(context, id) {
      return await getSkuInfoByOrderId(id)
    },
    async getSkuInfoByOrderIdForIpad(context, id) {
      return await getSkuInfoByOrderIdForIpad(id)
    },
    async requestRefund(context, data) {
      return await requestRefund(data)
    },
    async requestRefundForIpad(context, data) {
      return await requestRefundForIpad(data)
    },
    async guaranteeServiceList(context, orderId) {
      return await guaranteeServiceList(orderId)
    },
    async guaranteeServiceListForIpad(context, orderId) {
      return await guaranteeServiceListForIpad(orderId)
    },
    async getStatusTracking(context, orderId) {
      return await getStatusTracking(orderId)
    },
    async getStatusTrackingForIpad(context, orderId) {
      return await getStatusTrackingForIpad(orderId)
    },
    async checkOrderTimeExpire(context, orderId) {
      return await checkOrderTimeExpire(orderId)
    },
    async checkOrderTimeExpireForIpad(context, orderId) {
      return await checkOrderTimeExpireForIpad(orderId)
    },
    async getRefundOrder(context, id) {
      return await getRefundOrder(id)
    },
    async getRefundOrderForIpad(context, id) {
      return await getRefundOrderForIpad(id)
    },
    async getAvailableAndPrintCashierCounterList(context) {
      return await getAvailableAndPrintCashierCounterList()
    },
    async printOnlineTicket(context, data) {
      return await printOnlineTicket(data)
    },
    async printOnlineTicketForIpad(context, data) {
      return await printOnlineTicketForIpad(data)
    },
    async printOnlineRefundTicket(context, data) {
      return await printOnlineRefundTicket(data)
    },
    async printOnlineRefundTicketForIpad(context, data) {
      return await printOnlineRefundTicketForIpad(data)
    },
    async getRefundDesById(context, id) {
      return await getRefundDesById(id)
    },
    async getRefundDesByIdForIpad(context, id) {
      return await getRefundDesByIdForIpad(id)
    },
    async getOnlineOrderPickingTime(context, id) {
      return await getOnlineOrderPickingTime(id)
    },
    async getOnlineAmountCollected(context, id) {
      return await getOnlineAmountCollected(id)
    },
    async getOnlineActualAmountReceived(context, id) {
      return await getOnlineActualAmountReceived(id)
    },
    async getOnlineActualAmountReceivedForIpad(context, id) {
      return await getOnlineActualAmountReceivedForIpad(id)
    },
    async getAccountCounter(context) {
      return await getAccountCounter()
    },
    async checkOrderIfPrizeDraw(context, id) {
      return await checkOrderIfPrizeDraw(id)
    },
  },
}
