import {
  getAwardZoneLabel,
  sweepstakesStatistic,
  sweepstakesList,
  shelfProducts,
  getAwardZone,
  addActivities,
  eventDetails,
  getStateTracking,
  getWinningRecord,
  getWriteOffInfoForIpad,
  productListForIpad,
  confirmWriteOffForIpad,
  changeProductForIpad,
  activityCount,
} from '@/api/shop/marketingActivities'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getAwardZoneLabel(context) {
      return await getAwardZoneLabel()
    },
    async sweepstakesStatistic(context) {
      return await sweepstakesStatistic()
    },
    async sweepstakesList(context, params) {
      return await sweepstakesList(params)
    },
    async shelfProducts(context, params) {
      return await shelfProducts(params)
    },
    async getAwardZone(context, price) {
      return await getAwardZone(price)
    },
    async addActivities(context, data) {
      return await addActivities(data)
    },
    async eventDetails(context, id) {
      return await eventDetails(id)
    },
    async getStateTracking(context, id) {
      return await getStateTracking(id)
    },
    async getWinningRecord(context, params) {
      return await getWinningRecord(params)
    },
    async getWriteOffInfoForIpad(context, code) {
      return await getWriteOffInfoForIpad(code)
    },
    async productListForIpad(context, params) {
      return await productListForIpad(params)
    },
    async confirmWriteOffForIpad(context, data) {
      return await confirmWriteOffForIpad(data)
    },
    async changeProductForIpad(context, data) {
      return await changeProductForIpad(data)
    },
    async activityCount(context, params) {
      return await activityCount(params)
    },
  },
}
